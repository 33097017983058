import React, { useState } from "react";
import DuoIcon from "@mui/icons-material/Duo";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";
import { Box, IconButton, Tooltip } from "@mui/material";
import { ILogRow } from "../../api/types/logs";
import { useMutation } from "@tanstack/react-query";
import { updateLogByCalendar } from "../../api/Logs";
import { todayBrazilDate } from "../../utils/calendarConfig";
import ArticleIcon from "@mui/icons-material/Article";
import ContactsIcon from "@mui/icons-material/Contacts";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { Subscription } from "../../api/types/patient";

interface ActionButtonsProps {
  row: ILogRow; // Substitua 'any' pelo tipo apropriado do objeto 'row'
  activeSubscriptions: Subscription;
  pastdueSubscriptions?: Subscription;
}

const ToolsAgenda: React.FC<ActionButtonsProps> = ({
  row,
  pastdueSubscriptions,
  activeSubscriptions,
}) => {
  const [copied, setCopied] = useState(false);

  const [dateSelected, setDateSelected] = useState<Dayjs | null>(dayjs());
  const handleCopyLink = (linkMeet: string) => {
    navigator.clipboard
      .writeText(linkMeet)
      .then(() => {
        setCopied(true);
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
      });
  };

  const getLogByCalendarMutation = useMutation({
    mutationFn: updateLogByCalendar,
  });

  return (
    <Box display="flex">
      <IconButton
        href={`https://api.whatsapp.com/send?phone=${row.patient.phone_local_code}${row.patient.phone_number}`}
        target="_blank"
      >
        <Tooltip title="Contato paciente">
          <WhatsAppIcon />
        </Tooltip>
      </IconButton>
      <Box>
        {row.patient.sessoes.length > 0 ? (
          <IconButton
            href={`${row.patient.sessoes[0].linkMeet}`}
            target="_blank"
            //   onClick={() => handleCopyLink(row.sessoes[0].linkMeet)}
          >
            <Tooltip title="Link Meet">
              <DuoIcon />
            </Tooltip>
          </IconButton>
        ) : (
          <IconButton
            href="https://psidofuturoclinica.com/atendimento/agendamento"
            target="_blank"
          >
            <Tooltip title="Página de Agendamento">
              <EditCalendarIcon />
            </Tooltip>
          </IconButton>
        )}
        {/* <IconButton onClick={() => handleGetLogByCalendar(row)}>
          <Tooltip title="Buscar eventos no calendário">
            <ArrowCircleDownIcon />
          </Tooltip>
        </IconButton> */}
        <IconButton
          href={`https://digitalmanager.guru/admin/contacts/${row.patient.id_contact_guru}`}
          target="_blank"
        >
          <Tooltip title="Dados do paciente na Guru">
            <ContactsIcon />
          </Tooltip>
        </IconButton>
        {activeSubscriptions && (
          <IconButton
            href={`https://digitalmanager.guru/admin/subscriptions/${activeSubscriptions.subscription_code}`}
            target="_blank"
          >
            <Tooltip title="Assinatura do paciente">
              <LocalOfferIcon />
            </Tooltip>
          </IconButton>
        )}
        {pastdueSubscriptions && (
          <IconButton
            color="error"
            href={`https://digitalmanager.guru/admin/subscriptions/${pastdueSubscriptions.subscription_code}`}
            target="_blank"
          >
            <Tooltip title="Assinatura atrasada">
              <LocalOfferIcon />
            </Tooltip>
          </IconButton>
        )}
        <IconButton disabled>
          <Tooltip title="Gerar Recibo">
            <ArticleIcon />
          </Tooltip>
        </IconButton>
      </Box>
    </Box>
  );
};

export default ToolsAgenda;
