import http from "./http";
import { IClientEmail } from "./types/client";

export async function SendEmailConfirmation(clientEmail: IClientEmail) {
  const response = await http.post(`/api/otp/send`, clientEmail);
  console.log("response", response.data);
  return response.data;
}

export async function EmailTokenConfirmation(token: string) {
  const response = await http.get(`/api/otp/check/${token}`);
  return response.data;
}
