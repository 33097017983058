import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const CenterWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  justifyItems: "center",
  flexDirection: "column",
  gap: "1rem",
  height: "100%",
  padding: 0,
  [theme.breakpoints.up("md")]: { padding: theme.spacing(2) },
}));

export { CenterWrapper };
