import LogoButton from "../LogoButton";
import MenuItem from "@mui/material/MenuItem";
import DefaultButton from "../DefaultButton";
import { NavWrapper, NavLinks, NavMenu, NavLink } from "./styles";
import { AppBar, Typography } from "@mui/material";
import MenuNavBar from "./components/Menu";
import { Link } from "react-router-dom";

function NavBar() {
  return (
    <AppBar position="sticky">
      <NavWrapper>
        <LogoButton />
        <NavLinks>
          <NavLink
            style={{ textDecoration: "none", textTransform: "none" }}
            href="/#ComoFunciona"
          >
            Como Funciona
          </NavLink>
          <NavLink
            style={{ textDecoration: "none", textTransform: "none" }}
            href="/#Sobre"
          >
            Sobre Nós
          </NavLink>

          {/* <NavLink
            style={{ textDecoration: "none", textTransform: "none" }}
            href="/#Testemunho"
          >
            Depoimentos
          </NavLink> */}
          <DefaultButton
            href="https://api.whatsapp.com/send?phone=1131649167"
            variant="contained"
            style={{ color: "white" }}
          >
            Já sou paciente
          </DefaultButton>
        </NavLinks>
        <NavMenu>
          <DefaultButton
            href="https://api.whatsapp.com/send?phone=1131649167"
            variant="contained"
            style={{ color: "white" }}
          >
            Já sou paciente
          </DefaultButton>
        </NavMenu>
      </NavWrapper>
    </AppBar>
  );
}

export default NavBar;
