import React, { createContext, useState } from "react";
import { getWeekEvents, IAvalibleEvent, IWeekRequest } from "../api/Agenda";


export interface IAgendaContext { 
    getProfessionalWeek: (weekRequest: IWeekRequest) => Promise<IAvalibleEvent[]>
}

export const AgendaContext = createContext<IAgendaContext | null>(null);

interface IProp {
    children: React.ReactNode | React.ReactNode []
}

function AgendaProvider({children}: IProp) {
    

    async function getProfessionalWeek (weekRequest: IWeekRequest) {
        const week = await getWeekEvents(weekRequest)
        return week
    }
    return (
        <AgendaContext.Provider value={{getProfessionalWeek}}>
        {children}
        </AgendaContext.Provider>
    )
}
export { AgendaProvider }
export default AgendaContext;