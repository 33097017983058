import * as React from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useQuery } from "@tanstack/react-query";
import { getLogs } from "../../api/Logs";
import { Box, Typography } from "@mui/material";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

export function InfoPanel() {
  const { isLoading, isError, data, error } = useQuery(["logs"], getLogs);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Data e Hora da consulta</StyledTableCell>
              <StyledTableCell align="right">Email do Paciente</StyledTableCell>
              <StyledTableCell align="right">Nome do Paciente</StyledTableCell>
              <StyledTableCell align="right">Nome Psicologa</StyledTableCell>
              <StyledTableCell align="right">Tipo Sessao</StyledTableCell>
              <StyledTableCell align="right">What Psicologa</StyledTableCell>
              <StyledTableCell align="right">Status Compra</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((item, index) => (
              <StyledTableRow key={item.dataHoraConsulta}>
                <StyledTableCell component="th" scope="row">
                  {item.dataHoraConsulta}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.emailPaciente}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.nomePaciente}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.nomePsicologa}
                </StyledTableCell>
                {/* <StyledTableCell align="right">
                  {item.tipoSessao}
                </StyledTableCell> */}
                <StyledTableCell align="right">
                  {item.whatPsicologa}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {item.statusCompra}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
