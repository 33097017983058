import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
} from "react";
import { IAppointment } from "../components/week/type";

export enum Steps {
  first = 0,
  second = 1,
  third = 2,
}
export interface IContext {
  activeStep: any;
  skipped: Set<number>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  setSkipped: Dispatch<SetStateAction<Set<number>>>;
  handleNext: () => void;
  handleBack: () => void;
  handleSkip: () => void;
}

export const AppointmentContext = createContext<IContext | null>(null);

interface IProp {
  children: React.ReactNode | React.ReactNode[];
}
function AppointmentProvider({ children }: IProp) {
  const [activeStep, setActiveStep] = React.useState(Steps.first);
  const [skipped, setSkipped] = React.useState(new Set<number>());

  const isStepSkipped = (step: number) => {
    return skipped.has(step);
  };

  function handleNext() {
    if (activeStep <= 2) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }

  function handleBack() {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }

  function handleSkip() {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  }

  function handleReset() {
    setActiveStep(0);
  }

  return (
    <AppointmentContext.Provider
      value={{
        activeStep,
        setActiveStep,
        skipped,
        setSkipped,
        handleNext,
        handleSkip,
        handleBack,
      }}
    >
      {children}
    </AppointmentContext.Provider>
  );
}
export { AppointmentProvider };
export default AppointmentContext;
