import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { getPatients } from "../../../api/Patient";
import { Patient } from "../../../api/types/patient";
import { Autocomplete, TextField, Typography } from "@mui/material";
import InternalNavBar from "../../../components/InternalControl.tsx/InternalNavBar";

interface IPatientChoose {
  _id: string;
  name: string;
}

export default function DashboardPatient() {
  const [patient, setPatient] = React.useState<IPatientChoose | null>(null);

  const [patients, setPatients] = useState<Patient[] | null>(null);
  const [choosePatient, setChoosePatient] = React.useState<IPatientChoose[]>(
    []
  );

  const [patientInput, setPatientInput] = React.useState("");

  const mutationPatients = useMutation(getPatients, {
    onSuccess: (data) => {
      console.log("Patients data:", data); // Verifique se os dados estão corretos aqui
      setPatients(data);
      const patientOptions = data.map((patient: Patient) => ({
        _id: patient._id as string,
        name: patient.name,
      }));
      setChoosePatient(patientOptions);
    },
    retry: 0,
  });

  useEffect(() => {
    mutationPatients.mutateAsync();
  }, []);

  return (
    <div>
      <InternalNavBar />
      <Autocomplete
        value={patient}
        onChange={(
          event: React.SyntheticEvent<Element, Event>,
          newValue: IPatientChoose | null
        ) => {
          if (newValue) {
            setPatient(newValue);
          }
        }}
        includeInputInList
        inputValue={patientInput}
        onInputChange={(event, newInputValue) => {
          console.log("Input value:", newInputValue); // Verifique o valor do input aqui
          setPatientInput(newInputValue);
        }}
        id="pacientes"
        options={choosePatient.sort((a, b) => a.name.localeCompare(b.name))}
        sx={{ width: 300 }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => <TextField {...params} label="Pacientes" />}
      />
    </div>
  );
}
