import React, { createContext, useContext, useMemo, useState } from "react";
import { reschedulingEvent, ReserveEvent } from "../api/Agenda";
import { EmailTokenConfirmation, SendEmailConfirmation } from "../api/Email";
import { IReserveEvent, IReservEventAnswer } from "../api/types/agenda";
import { IProfessional } from "../api/types/professionals";
import AppointmentContext, { IContext } from "./StepperContext";
import {
  AppointmentControlContext,
  IChatAnswers,
  IProp,
  IReschedulingFeedback,
} from "./types/controlContextTypes";
import { IChatOptionLayout } from "../pages/match/type";
import { IChatOptions, getChatOptions } from "../api/ChatApi";

export const ControlContext =
  createContext<AppointmentControlContext | null>(null);

function ControlProvider({ children }: IProp) {
  const [proList, setProList] = React.useState<IProfessional[] | undefined>();
  const [proSelected, setProSelected] = React.useState<IProfessional>();
  const [isLoading, setIsLoading] = React.useState(false);
  const [email, setEmail] = useState("");
  const [chatAnswers, setChatAnswers] = useState<IChatAnswers | undefined>();
  const [token, setToken] = useState("");
  const [isTokenConfirmed, SetIsTokenConfirmed] =
    useState<"confirmed" | "invalid" | "empty">("empty");
  const { setActiveStep } = useContext(AppointmentContext) as IContext;
  const [checkout, setCheckout] = useState<IReservEventAnswer[]>([]); //id do evento
  const [isPaymentTime, setIsPaymentTime] = useState(false); //id do evento
  const [chatOptions, setChatOptions] = useState<IChatOptionLayout[]>([]);
  const chatData = useMemo(() => {
    const fetchData = async () => {
      async function handleGetChatOption() {
        try {
          setIsLoading(true);
          const options = await getChatOptions(); // frases do back (id, texto)
          const chatVersion = options.map((element: IChatOptions) => {
            const option = {
              content: element.text,
              value: element._id,
              goTo: "almost",
            };
            return option;
          });

          setChatOptions(chatVersion);
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      }
      handleGetChatOption();
    };

    fetchData();
  }, []);

  function SetSelectProfessional(professional: IProfessional) {
    setProSelected(professional);
  }

  function handleSetList(data: IProfessional[]) {
    setIsLoading(true);
    setProList(data);
    setIsLoading(false);
  }

  function setSessionStorageItem(key: string, value: string) {
    return new Promise<void>((resolve, reject) => {
      try {
        sessionStorage.setItem(key, JSON.stringify(value));
        resolve();
      } catch (error) {
        reject(error);
      }
    });
  }
  async function ReserveAgenda(data: IReserveEvent) {
    const reserve = await ReserveEvent(data);
    console.log("reserve", reserve[0].eventId);

    await setSessionStorageItem("id", reserve[0].eventId).then(() => {
      setCheckout(reserve);

      setIsPaymentTime(true);
    });
  }
  async function ReschedulingEvent(bookingId?: string, eventId?: string) {
    const reserve = await reschedulingEvent({
      bookingId: bookingId,
      eventId: eventId,
      start: JSON.parse(sessionStorage.getItem("start") || ""),
    });

    console.log(reserve);
    return reserve;
  }

  async function SetClientEmail(clientEmail: string) {
    setIsLoading(true);
    setEmail(clientEmail);

    // try {
    //   const resp = await SendEmailConfirmation({
    //     name: chatAnswers?.name,
    //     email: clientEmail,
    //   });
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   setIsLoading(false);
    // }
  }

  async function TokenVerification(token: string) {
    setToken(token);

    try {
      const { data } = await EmailTokenConfirmation(token);

      if (data) {
        SetIsTokenConfirmed("confirmed");
        //in case the token is confirmed, then book the agenda
        console.log("email", email);
        ReserveAgenda({
          name: chatAnswers?.name,
          email: email,
          phone: chatAnswers?.whatsapp,
          // eventId: eventId,
          //eventId: JSON.parse(sessionStorage.getItem("id") || ""),
          proId: proSelected?._id,
          start: JSON.parse(sessionStorage.getItem("start") || ""),
          // start: availableEventSelected?.start,
          //
        });

        setActiveStep(2);
      } else {
        SetIsTokenConfirmed("invalid");
      }
    } catch (error) {
      SetIsTokenConfirmed("invalid");
    }
  }

  function handleSetAnswers(data: IChatAnswers) {
    setChatAnswers(data);
    data.name = data.name
      .replace(/[^a-zA-ZáéíóúÁÉÍÓÚãõÃÕçÇ ]+/g, "")
      .toLowerCase()
      .replace(/^\w/, (c) => c.toUpperCase());

    console.log(data.name);
    sessionStorage.setItem("isChatAnswer", JSON.stringify(true));
    sessionStorage.setItem("chatAnswers", JSON.stringify(data));
    // filtro para pegar profissionais com essas experiencias
  }

  return (
    <ControlContext.Provider
      value={{
        // availableEventSelected,
        email,
        SetClientEmail,
        handleSetAnswers,
        chatAnswers,
        proList,
        proSelected,
        handleSetList,
        TokenVerification,
        isTokenConfirmed,
        SetIsTokenConfirmed,
        SetSelectProfessional,
        isPaymentTime,
        checkout,
        ReschedulingEvent,
        chatOptions,
      }}
    >
      {children}
    </ControlContext.Provider>
  );
}
export { ControlProvider };
export default ControlContext;
