import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../pages/home/Home";
import ConfirmAppointment from "../pages/appointment/ConfirmAppointment";
import Match from "../pages/match/Match";
import Appointment from "../pages/appointment/Appointment";
import Payment from "../pages/payment/Payment";
import AlreadyPatient from "../pages/AlreadyPatient/AlreadyPatient";
import SignIn from "../pages/SingIn/SingIn";
import { InfoPanel } from "../pages/internal/InfoPanel";
import InteralAppointment from "../pages/internal/InteralAppointment";
import PackageFollowUp from "../pages/private/InternalControl/PackageFollowUp";
import PatientProfile from "../pages/private/patient/PatientProfile";
import DashboardPatient from "../pages/private/patient/DashboardPatient";
import ClinicHome from "../pages/private/InternalControl/ClinicHome";
import NotFound from "../pages/notFound/NotFound";
import ComingSoon from "../pages/ComingSoon/ComingSoon";

function AppRoutes() {
  return (
    <Router>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<ComingSoon />} />

        {/* <Route path="/confirmar-agendamento" element={<ConfirmAppointment />} />
        <Route
          path="/confirmar-agendamento/:proId/:patientId/:status/:marketplace_id/:name/:email/:namePsi/:patientPhone"
          element={<ConfirmAppointment />}
        />
        <Route path="/encontre-seu-psicologo" element={<Match />} />
        <Route path="/agendamento" element={<Appointment />} />
        <Route
          path="/agendamento/:bookingId/:proId/:eventId"
          element={<Appointment />}
        /> 
        <Route path="/finalizar-agendamento" element={<Payment />} />
        <Route path="/ja-sou-paciente" element={<AlreadyPatient />} />*/}

        {/* Rotas privadas */}
        <Route path="/autenticacao" element={<SignIn />} />
        <Route path="/clinica/dashboard" element={<InfoPanel />} />
        <Route
          path="/clinica/agendamento-interno"
          element={<InteralAppointment />}
        />
        <Route
          path="/clinica/acompanhar-pacote"
          element={<PackageFollowUp />}
        />
        <Route
          path="/clinica/detalhes-paciente/:id/:proName/:proId"
          element={<PatientProfile />}
        />
        <Route path="/clinica/pacientes" element={<DashboardPatient />} />
        <Route path="/clinica/*" element={<ClinicHome />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}

export default AppRoutes;
