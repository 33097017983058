import http from "./http";
const path = "/api/auth";

export interface ILogin {
  email: string;
  password: string;
}

export interface IUser {
  _id: string;
  email: string;
}
export interface IAuthResponse {
  data: {
    email: string;
    //password: string;
    _id: string;
  };
  message: string;
}
export async function login(credentials: ILogin) {
  const response = await http.post(`${path}/login`, credentials);
  const user: IUser = response.data.data;

  if (response.headers["set-cookie"]) {
    localStorage.setItem(
      "authCookie",
      JSON.stringify(response.headers["set-cookie"])
    );
  }
  return user;
}
export async function logout() {
  const response: IAuthResponse = await http.get(`${path}/logout`);
  const user = response.data;
  return user;
}
export async function signup() {
  const response = await http.get(`${path}/signup`);
  return response.data.data;
}
