import { utcToZonedTime } from "date-fns-tz";

export const months = [
  "Jan",
  "Fev",
  "Mar",
  "Abr",
  "Mai",
  "Jun",
  "Julho",
  "Agosto",
  "Set",
  "Out",
  "Nov",
  "Dez",
];

export const days = ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"];
export const dayOfWeekTranslation: { [key: string]: string } = {
  Mon: "Seg",
  Tue: "Ter",
  Wed: "Qua",
  Thu: "Qui",
  Fri: "Sex",
  Sat: "Sáb",
  Sun: "Dom",
};

export const weekPortuguese = [
  "Domingo",
  "Segunda",
  "Terça-feira",
  "Quarta-feira",
  "Quinta-feira",
  "Sexta-feira",
  "Sábado",
];

export const hours = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

export function getFirstAndLast(name: string | undefined) {
  const names = name?.split(" ");
  let firstName;
  let lastName;

  if (names != null) {
    firstName = names[0];
    lastName = names[names.length - 1];
  }

  return `${firstName ?? ""} ${lastName ?? ""}`;
}

const now = new Date();
const nowUTC = new Date(
  Date.UTC(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
    now.getSeconds()
  )
);
const timeZone = "America/Sao_Paulo";
const dateBrasil = utcToZonedTime(nowUTC, timeZone);
export const todayBrazilDate = dateBrasil.toISOString().slice(0, 10);

export const formatDateToString = (date: Date): string => {
  const timeZone = "America/Sao_Paulo";
  const dateUTC = new Date(
    Date.UTC(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
  );
  const dateBrasil = utcToZonedTime(dateUTC, timeZone);
  return dateBrasil.toISOString().slice(0, 10);
};
