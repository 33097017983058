import React, { useEffect } from "react";
import * as Layout from "./ClinicHome.layout";
import InternalNavBar from "../../../components/InternalControl.tsx/InternalNavBar";
import { Paper, TableContainer } from "@mui/material";
import ClinicTable from "../../../components/InternalControl.tsx/tables/ClinicTableContainer";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const DATE_LOCAL_STORAGE_KEY = "selectedDate";

export default function ClinicHome() {
  const [date, setDate] = React.useState<Dayjs | null>(() => {
    // Verifica se a data existe no localStorage
    const storedDate = localStorage.getItem(DATE_LOCAL_STORAGE_KEY);
    if (storedDate) {
      return dayjs(storedDate); // Se existir, usa a data do localStorage
    } else {
      // Se não existir, retorna o dia de amanhã
      const tomorrow = dayjs().add(1, "day");
      return tomorrow;
    }
  });
  const navigate = useNavigate();

  // Salva a data no localStorage sempre que ela for alterada
  useEffect(() => {
    if (date) {
      localStorage.setItem(DATE_LOCAL_STORAGE_KEY, date.toISOString());
    }
  }, [date]);

  const handleSelectTomorrow = () => {
    const tomorrow = dayjs().add(1, "day");
    setDate(tomorrow);
  };
  return (
    <Layout.ClinicWrap>
      <InternalNavBar />
      <Layout.Border>
        <Box p={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Escolher data de sessão agendada"
                value={date}
                onChange={(newValue) => setDate(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>{" "}
        </Box>
        <Layout.Main lg={12}>
          <TableContainer component={Paper}>
            <ClinicTable date={date} />
          </TableContainer>
        </Layout.Main>
      </Layout.Border>
    </Layout.ClinicWrap>
  );
}
