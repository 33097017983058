import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import AppointmentContext, { IContext } from '../context/StepperContext'
import Logo from '../Assets/logo-string.png'
import React, { useContext, useEffect } from 'react'

export default function LogoButton() {
    const navigate = useNavigate ()
    const {setActiveStep} = useContext(AppointmentContext) as IContext

    useEffect ( () => {
        setActiveStep(0)
    }, [])
    return (
        <div>
            <Button sx={{
                ':hover': {
                    bgcolor: 'transparent', // theme.palette.primary.main
                },}}
                variant='text'
                color='success' 
                onClick={() => navigate("/")}
            >
                <img className='logo' style={{width: "120px", height:"auto"}} src={Logo} alt='Logo Psi do futuro' />
            </Button>
        </div>
    )
}
