import * as React from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import background from "../../Assets/backgroundGreen.png";
import logo from "../../Assets/Logo-white.png";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useMutation } from "@tanstack/react-query";
import { IUser, login } from "../../api/Authentication";

const defaultTheme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const [user, setUser] = React.useState<any>(null); // Add 'any' or specify the type of the user data

  const { mutate: loginMutation } = useMutation<
    IUser,
    unknown,
    { email: string; password: string },
    unknown
  >(({ email, password }) => login({ email, password }), {
    onSuccess: (data) => {
      setUser(data);
      navigate("/clinica");
    },
    onError: (error) => {},
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    try {
      // Faz a chamada da função de login (loginMutation) passando os dados do formulário
      await loginMutation({
        email: data.get("email") as string,
        password: data.get("password") as string,
      });
    } catch (error) {
      // O tratamento de erro já foi feito no onError do useMutation
      console.error("Erro ao fazer login:", error);
    }
  };

  React.useEffect(() => {
    if (user) {
      navigate("/clinica");
    }
  }, [user]);

  function handleCredentialResponse(
    response: google.accounts.id.CredentialResponse
  ) {
    var userObject = jwt_decode(response.credential) as { [key: string]: any };
    console.log(userObject);
    setUser(userObject);
  }
  React.useEffect(() => {
    /*Global Google*/
    window.google.accounts.id.initialize({
      client_id:
        "668878346520-orsqjhppi443meu55v4db0kt24eg06aa.apps.googleusercontent.com",
      callback: (data) => handleCredentialResponse(data),
      state_cookie_domain: "https://localhost:3000",
    });

    const signInDiv = document.getElementById("singInDiv");
    if (signInDiv) {
      google.accounts.id.renderButton(signInDiv, {
        theme: "outline",
        size: "large",
        type: "standard",
      });
    }
  }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />

        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            display: { xs: "none", md: "grid" },
            backgroundImage: `url(${background})`,
            backgroundRepeat: "no-repeat",
            backgroundColor: (t) =>
              t.palette.mode === "light"
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          {" "}
          <Box
            width="100%"
            display="flex"
            justifyContent="center"
            height="100%"
            alignItems="center"
          >
            <img src={logo} style={{ width: "30%", height: "auto" }} />
          </Box>
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography component="h1" variant="h5">
              Clínica
            </Typography>
            <div id="singInDiv" />
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1 }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                color="success"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Entrar
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
