const COLORS = {
  green: "#637D3B",
  greenLight: "#496522",
  greenDark: "rgb(69, 107, 38);",
  primaryLight: "rgb(69, 107, 38)", // "#F0E1CD",
  primaryGreen: "#739756",

  black: "#000000",
  white: "#ffffff",

  darkBlue: "#024869",
  darkBlue_light: "#407497",
  darkBlue_dark: "#00213e",

  teal: "#48b79e",
  teal_light: "#7deacf",
  teal_dark: "#008670",
  bgTeal: "#f0f9fc",

  blue: "#53b3d4",
  blue_light: "#89e5ff",
  blue_dark: "#0682a3",
  blue_deep: "#072448",
  blue_button_hover: "#4FABCB",
  blue_button_focus: "#489DBA",
  blue_alice: "#EEF7FB",

  sunshine: "#fbc912",
  sunshine_light: "#fffc55",
  sunshine_dark: "#c29900",

  coral: "#ff7f50",
  coral_light: "#feaf7e",
  coral_dark: "#c64f25",

  errorRed: "#FF0000",
  red: "#E65C5A",
  red_light: "#ff8d87",
  red_dark: "#ad2a31",

  maroon: "#A94442",
  maroon_light: "#df746d",
  maroon_dark: "#74131b",

  alert: {
    warning: "#a94442",
    error: "#b00020",
  },
  accents: {
    turquoiseGreen: "#ecf8f5",
    pistachio: "#fcfefa",
    egg: "#f0f9fc",
    gold: "#fffbb6",
    lightCoral: "#fff3ee",
    quartz: "#fff2f2",
  },
  grays: {
    charcoal: "#4d4d4d",
    darkGray: "#2E2E2E",
    gray40: "#666666",
    gray: "#565656",
    lightGray: "#cccccc",
    lighterGray: "#dddddd",
    lightestGray: "#e6e6e6",
  },

  rose: "#F0E1CD",
  paper: "#DEDEDD",
  greens: {
    green100: "#BDD9BF",
    green200: "#8BC080",
    green300: "#9BB99E",
    green400: "#36A97B",
    green500: "#005A39",
    green600: "#007347",
  },
  greenOliver: {
    green100: "#BCC6AB",
    green200: "#9CAE7F",
    green300: "#7E965A",
    green400: "#637D3B",
    green500: "#496522",
  },
};

export default COLORS;
