export const statusColors: { [key: string]: string } = {
  "": "#ff9800", //palette.warning.main
  agendada: "#4caf50", // palette.error.main
  Realizada: "#4caf50", //palette.success.main
};
export const statusPaymentColors: { [key: string]: string } = {
  Pago: "#4caf50", //"Sessão Inicial Pago": "info",
  Renovacao: "#ffc107",
  Atrasado: "#f44336",
  Cancelado: "#e0e0e0",
  Abandonado: "#7b1fa2",
  Análise: "#e1f5fe",
  Bloqueado: "#827717",
  Estorno: "#e1bee7",
  Concluido: "#69f0ae",
  Disputa: "Disputa",
  Expirado: "Expirado",
  Reembolsado: "#9575cd",
  Rejeitado: "#e65100",
  Agendado: "#9e9e9e",
  Aguardando: "#ffeb3b",
};

// default material
// default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | string

export const typeSession: { [key: string]: string } = {
  semanal_recorrente: "Semanal Recorrente", //"Sessão Inicial Pago": "info",
  pacote: "Pacote",
  inicial: "Sessão Inicial",
  casal_inicial: "Sessão Casal Inicial",
  casal_pacote: "Sessão Casal Pacote",
};

export const paymentMethod: { [key: string]: string } = {
  pix: "Pix", //"Sessão Inicial Pago": "info",
  credit_card: "Cartão de Crédito",
  debit: "Cartão de Débito",
};
