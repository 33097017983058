import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { UserProfileParams } from "../../appointment/type";
import { useMutation } from "@tanstack/react-query";
import { getPatientById } from "../../../api/Patient";
import {
  Box,
  Chip,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Frequency,
  ISession,
  Patient,
  Subscription,
} from "../../../api/types/patient";
import { format } from "date-fns";
import { IProfessional } from "../../../api/types/professionals";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InternalNavBar from "../../../components/InternalControl.tsx/InternalNavBar";
export default function PatientProfile() {
  const { id, proName, proId } = useParams<UserProfileParams>();
  const [patient, setPatient] = useState<Patient | null>(null);

  const getSubscription = useMutation({
    mutationFn: getPatientById,
    onSuccess(data) {
      setPatient(data);
    },
  });
  useEffect(() => {
    if (id) {
      getSubscription.mutate(id);
    }
  }, [id]);
  const styles = {
    paper: {
      height: "100vh",
      padding: "16px",
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      marginTop: 1,
    },
    sectionTitle: {
      marginBottom: "16px",
    },
    tableContainer: {
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
      marginBottom: "16px",
    },
  };
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleExpand = (sessionId: string) => {
    setExpanded(expanded === sessionId ? false : sessionId);
  };

  return (
    <>
      <InternalNavBar />
      <Paper sx={styles.paper}>
        <Grid container>
          <Grid item lg={6} xs={12}>
            <Typography variant="h4" fontWeight={600} sx={styles.sectionTitle}>
              Detalhes
            </Typography>
            {patient && (
              <TableContainer sx={styles.tableContainer}>
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell variant="head">Nome:</TableCell>
                      <TableCell>{patient.name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">E-mail:</TableCell>
                      <TableCell>{patient.email}</TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell variant="head">Telefone:</TableCell>
                      <TableCell>{`${patient.phone_local_code}${patient.phone_number}`}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="head">Profissional:</TableCell>
                      <TableCell>{`${proName}`}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            )}

            <Typography variant="h4" fontWeight={600} sx={styles.sectionTitle}>
              Assinaturas
            </Typography>
            {patient && (
              <TableContainer sx={styles.tableContainer}>
                <Table>
                  <TableBody>
                    {patient.subscriptions.map((subscription: Subscription) => (
                      <TableRow key={subscription.id}>
                        <TableCell variant="head">Código:</TableCell>
                        <TableCell>{subscription.subscription_code}</TableCell>
                        <TableCell variant="head">Status:</TableCell>
                        <TableCell>
                          <Chip
                            label={
                              subscription.last_status === "active"
                                ? "Ativa"
                                : "Inativa"
                            }
                            color={
                              subscription.last_status === "active"
                                ? "primary"
                                : "default"
                            }
                          />
                        </TableCell>
                        <TableCell variant="head">Próximo pagamento:</TableCell>
                        <TableCell>
                          {format(
                            new Date(subscription.next_cycle_at),
                            "dd/MM/yyyy"
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item lg={6} xs={12}>
            <Typography variant="h4" fontWeight={600} sx={styles.sectionTitle}>
              Sessões
            </Typography>
            {patient && (
              <Grid container spacing={2} padding={4}>
                {patient && (
                  <Grid container spacing={2}>
                    {patient.sessoes?.map((session: ISession) => (
                      <Grid item key={session._id} lg={12}>
                        <Box display="flex">
                          <IconButton
                            aria-label="Expandir"
                            size="small"
                            onClick={() =>
                              session &&
                              session._id &&
                              handleExpand(session._id)
                            }
                          >
                            <ExpandMoreIcon />
                          </IconButton>
                          <Typography>
                            {session.data} - {session.hora}
                          </Typography>
                          <Chip
                            label={
                              session.paymentStatus === "aguardando"
                                ? "Aguardando"
                                : "Pago"
                            }
                            color={
                              session.paymentStatus === "pago"
                                ? "default"
                                : "primary"
                            }
                          />
                        </Box>
                        <Collapse in={expanded === session._id}>
                          <Paper sx={styles.tableContainer}>
                            <TableContainer>
                              <Table>
                                <TableBody>
                                  <TableRow>
                                    <TableCell variant="head">
                                      ID da Sessão:
                                    </TableCell>
                                    <TableCell>{session._id}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell variant="head">Data:</TableCell>
                                    <TableCell>{session.data}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell variant="head">Hora:</TableCell>
                                    <TableCell>{session.hora}</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Status:
                                    </TableCell>
                                    <TableCell>{session.status}</TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                              <Collapse
                                in={expanded === session._id}
                                timeout="auto"
                                unmountOnExit
                              >
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell variant="head">
                                        Link Meet:
                                      </TableCell>
                                      <TableCell>
                                        <a
                                          href={session.linkMeet}
                                          target="_blank"
                                          rel="noopener noreferrer"
                                        >
                                          <ArrowForwardIcon fontSize="small" />
                                          Abrir Meet
                                        </a>
                                      </TableCell>
                                    </TableRow>
                                    {/* Adicione mais informações das sessões aqui, se necessário */}
                                  </TableBody>
                                </Table>
                              </Collapse>
                            </TableContainer>
                          </Paper>
                        </Collapse>
                      </Grid>
                    ))}
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Paper>
    </>
  );
}
