import http from "./http";
import { IClientEmail } from "./types/client";
import { Log } from "./types/logs";

export async function getLogs(): Promise<Log[]> {
  const response = await http.get(`/api/logs`);
  console.log("response", response.data.data);
  return response.data.data;
}

/// added

export interface IUpdateStatusReq {
  id: string;
  status: string;
}

// trigger Gab
// ----------------- rota -----------------
// https://psidofuturoclinica.com/api/logs
// ----------------- body -----------------
// id: string, status: string;

export async function updateSessionStatus(body: IUpdateStatusReq) {
  console.log("atualizacao de status");
  const response = await http.post(`/api/logs`, body);
  console.log("response", response.data.data);
  return response.data.data;
}

interface IUpdateLogByCalendarReq {
  patientId: string;
  proId: string;
  dayMin: string;
  dayMax: string;
  id_contact_guru: string;
}
export async function updateLogByCalendar(
  body: IUpdateLogByCalendarReq
): Promise<string> {
  console.log(body);
  const response = await http.post("api/logs/updateLogByCalendar", body);
  return response.data.message;
  return "ok";
}
