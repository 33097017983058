import { createTheme } from "@mui/material";
import COLORS from "./colors";
// import { deDE as dataGridDeDE } from '@mui/x-data-grid';
import { ptBR as corePtBR } from "@mui/material/locale";
import { ptBR } from "@mui/x-date-pickers/locales";
import breakpoints from "./base/breakpoints";

export const PsiTheme = createTheme(corePtBR, ptBR, {
  typography: {
    fontFamily: "Bree",
    h1: {
      fontFamily: "Bree",
      fontSize: "3rem",
    },
    button: {
      fontFamily: "Bree",
    },
    subtitle1: {
      fontFamily: "Bree",
      color: COLORS.grays.gray40,
    },
  },
  palette: {
    primary: {
      //main: "#637E3B",
      //main: "#334d10",
      //main: "rgb(177, 187, 164)",
      main: "#36822E", // "#BCC6ab",
      light: "#BCC6ab", // "#7E965A",
      dark: "#286c21", //"#9Cad80",
      //contrastText: "#334d10",
      contrastText: "#fff",
    },
    secondary: {
      main: "#fff",
    },
  },

  breakpoints: { ...breakpoints },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      html {
        scroll-behavior: smooth;
      },
        @font-face {
          font-family: 'Bree';
          src: url('../../fonts/Bree/Bree-Regular.woff') format('truetype');
          font-weight: normal;
          font-style: normal;
        },
        *::-webkit-scrollbar {
          width: 5px;
        }
        
        *::-webkit-scrollbar-track {
          background: gb(255, 255, 255);
        }
        
        *::-webkit-scrollbar-thumb {
          background-color: rgb(183, 183, 183);
          border-radius: 20px;
          border: 3px solid gb(255, 255, 255);
        }

      `,
    },
    MuiAppBar: {
      defaultProps: {
        color: "secondary",
        position: "static",
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        content: {
          flexGrow: 0,
        },
      },
    },
  },
});
