import { IAvalibleEvent } from "../../api/Agenda";

export const eventsMockup: IAvalibleEvent[] = [
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T00:00:00.000Z",
    end: "2023-06-22T01:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T01:00:00.000Z",
    end: "2023-06-22T02:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T02:00:00.000Z",
    end: "2023-06-22T03:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T03:00:00.000Z",
    end: "2023-06-22T04:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T04:00:00.000Z",
    end: "2023-06-22T05:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T05:00:00.000Z",
    end: "2023-06-22T06:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T06:00:00.000Z",
    end: "2023-06-22T07:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T07:00:00.000Z",
    end: "2023-06-22T08:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T08:00:00.000Z",
    end: "2023-06-22T09:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T09:00:00.000Z",
    end: "2023-06-22T10:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T10:00:00.000Z",
    end: "2023-06-22T11:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T11:00:00.000Z",
    end: "2023-06-22T12:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T12:00:00.000Z",
    end: "2023-06-22T13:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T13:00:00.000Z",
    end: "2023-06-22T14:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T14:00:00.000Z",
    end: "2023-06-22T15:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T15:00:00.000Z",
    end: "2023-06-22T16:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T16:00:00.000Z",
    end: "2023-06-22T17:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T17:00:00.000Z",
    end: "2023-06-22T18:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T18:00:00.000Z",
    end: "2023-06-22T19:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T19:00:00.000Z",
    end: "2023-06-22T20:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T20:00:00.000Z",
    end: "2023-06-22T21:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T21:00:00.000Z",
    end: "2023-06-22T22:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T22:00:00.000Z",
    end: "2023-06-22T23:00:00.000Z",
  },
  {
    id: "",
    summary: "Fake Event",
    start: "2023-06-22T23:00:00.000Z",
    end: "2023-06-23T00:00:00.000Z",
  },
];
