import React, { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const DefaultButton = styled(Button)<ButtonProps>(({ theme }) => ({
  textTransform: "none",
  minWidth: "120px",
  borderRadius: "25px",
  color: "#fff",
  backgroundColor: "#739756",
  "&:hover": {
    backgroundColor: "#456B26",
  },
  ":disabled": {
    backgroundColor: "#c4c4c4",
  },
}));

export const TextButton = styled(Button)<ButtonProps>(({ theme }) => ({
  margin: 0,
  padding: 0,
  paddingLeft: "3px",
  minWidth: "3rem",
  textTransform: "none",
  color: "#739756",
  "&:hover": {
    color: "#456B26",
  },
}));

export default DefaultButton;
