import http from "./http";
import * as types from "./types/professionals";

export interface IGetProfessionals {
  chatOptionId?: string;
  priceLevel?: string;
}

export async function getProfessionals(
  data: IGetProfessionals
): Promise<types.IProfessional[]> {
  const response = await http.post("/api/professionals", data);
  return response.data.data;
}
export interface IPatient {
  _id: string;
  name: string;
  email: string;
  id_psicologo: string;
  address: string;
  address_city: string;
  address_comp: string;
  address_country: string;
  address_district: string;
  address_number: string;
  address_state: string;
  address_state_full_name: string;
  address_zip_code: string;
  company_name: string;
  doc: string;
  phone_local_code: string;
  phone_number: string;
  marketplaceId: string;
  history: {
    data: string;
    mensagem: string;
    _id: string;
  }[];
  __v: number;
  eventId: string;
  tipo_sessao: string;
  tentativa_pagamento: number;
  sessoes: {
    idEvent: string;
    linkMeet: string;
    data: string;
    hora: string;
    status: string;
    _id: string;
  }[];
}

interface ProfAndPatients {
  professionals: types.IProfessional[];
  pacients: IPatient[];
  message: string;
}
export async function getAllProfessionalsAndPatients(): Promise<ProfAndPatients> {
  const response = await http.get("/api/getAllProfessionalsAndPatients");
  console.log(response);
  return response.data as ProfAndPatients;
}

export async function getProfessionalById(
  professionalId?: string
): Promise<types.IProfessional> {
  const response = await http.get(`/api/professionals/${professionalId}`);
  return response.data.data;
}

export async function searchProfessional(
  searchString: string
): Promise<types.ISearchAnswer> {
  const professionals = await http.get(
    `api/professionals/autocomplete/${searchString}`
  );
  return professionals.data;
}

export async function createProfessional(data: any) {
  const response = await http.get("/api", data);
  return response.data;
}

export async function updateProfessional(data: any) {
  const response = await http.get("/api", data);
  return response.data;
}

export async function deleteProfessional(data: any) {
  const response = await http.get("/api", data);
  return response.data;
}
