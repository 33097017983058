import http from "./http";
import { LogDataResponse, SubscriptionInfo, Transaction } from "./types/logs";

interface ISignature {
  _id?: string;
  data: Date;
  assinatura: string;
  ativo: boolean;
  history: string; // cancelado quando
  valor: string;
}
export interface LogData {
  id_paciente: string;
  sessoes: {
    idEvent: string;
    linkMeet: string;
    data: string;
    hora: string;
    status: string;
    _id: string;
  }[];
  id_psicologa: string;
  nomepsicologa: string;
  dataHoraConsulta: string;
  whatPaciente: string;
  whatPsi: string;
  pagamento: string;
  status_sessao: string;
  tipo_sessao: string;
  nome_paciente: string;
  data_pagamento: string;
  valor_pagamento?: number;
  assinatura?: ISignature[];
  metodo?: string;
}

interface LogResponse {
  data: LogData[];
}
export async function getClinicData(): Promise<LogResponse> {
  //precisa receber paciente (com dados como ) sessões agendadas , nome do psicologo, horario, agendamento , pagamento , status (card de evento)
  const response = await http.get(`/api/clinicData`);
  console.log("response: ", response.data);
  return response.data;
}

export async function allLogs(): Promise<LogResponse> {
  const response = await http.get(`/api/allLogs`);
  return response.data;
}

interface GetSessionByDateParams {
  date: string;
  page?: number;
}
export async function getSessionByDate(
  params: GetSessionByDateParams
): Promise<LogDataResponse> {
  const { date, page } = params;
  const response = await http.get(`/api/clinic/sessionByDate/${date}`, {
    params: { page: page !== undefined ? page : 1 }, // Use a default value of 1 if page is undefined
  });
  return response.data;
}

export async function getPatientAndProBySessionDate(
  params: GetSessionByDateParams
): Promise<LogDataResponse> {
  const { date, page } = params;
  const response = await http.get(
    `/api/clinic/patientAndProBySessionDate/${date}`,
    {
      params: { page: page !== undefined ? page : 1 }, // Use a default value of 1 if page is undefined
    }
  );
  return response.data;
}

export async function getGuruSubscription(
  id: string
): Promise<SubscriptionInfo[]> {
  const response = await http.get(`/api/guru/guruSubscription/${id}`);
  return response.data.subscription;
}

export async function getGuruTransactions(id: string): Promise<Transaction[]> {
  const response = await http.get(`/api/guru/guruTransactions/${id}`);

  console.log(response);
  return response.data.transactions;
}
