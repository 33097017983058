import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Grid,
  LinearProgress,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import logo from "../../Assets/logo-string.png";
// imagens de etapas
import etapa1 from "../../Assets/steps/etapa1-gradient.png";
import etapa2 from "../../Assets/steps/etapa2-gradient.png";
import etapa3 from "../../Assets/steps/etapa3-gradient.png";
// tradução e calendario
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { ptBR } from "../../utils/PortuguesCalendar/ptBR";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";
import { v4 as uuidv4 } from "uuid";
import TimeCardList from "../../components/week/TimeCardList";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Loading from "../../components/Loading";
import { confirmEvent, getStatus } from "../../api/Appointment";
import { useMutation } from "@tanstack/react-query";
import {
  IPatient,
  getAllProfessionalsAndPatients,
  getProfessionalById,
} from "../../api/Professional";
import { IProfessional } from "../../api/types/professionals";
import { useCheckMobileScreen } from "../../utils/CheckMobileScreen";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { formatarHora } from "../../utils/timeFormat";
import { Formik } from "formik";
import { VariantType, useSnackbar } from "notistack";
import { validationSchema } from "../appointment/Components/ValidationSchema";
import { IConfirmationEvent } from "../appointment/type";
import { weekPortuguese } from "../../utils/calendarConfig";
import HorarioAtendimentoTable from "./components/formatWorkSlots";

interface IProfessionalChoose {
  _id: string;
  name: string;
}

interface IPatientChoose {
  _id: string;
  name: string;
}
export default function InteralAppointment() {
  const [value, setValue] = useState<Dayjs | null>(dayjs());
  const isMobile = useCheckMobileScreen();

  //dados que serão alterados pelo formik
  const [nomePaciente, setNomePaciente] = useState("");
  const [emailPaciente, setEmailPaciente] = useState("");
  const [telefonePaciente, setTelefonePaciente] = useState("");

  const [time, setTime] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [step, setStep] = useState(0);

  const [pro, setPro] = React.useState<IProfessionalChoose | null>();
  const [patient, setPatient] = React.useState<IPatientChoose | null>();

  const [professional, setProfessional] = useState<IProfessional>();
  const [choosePro, setChoosePro] = React.useState<IProfessionalChoose[]>([]);
  const [choosePatient, setChoosePatient] = React.useState<IPatientChoose[]>(
    []
  );

  const [proInput, setProInput] = React.useState("");
  const [patientInput, setPatientInput] = React.useState("");

  useEffect(() => {
    console.log("proInput", proInput);
  }, [proInput]);

  const allProfessionalsMutation = useMutation({
    mutationFn: getAllProfessionalsAndPatients,
    onError: (error: Error) => {
      console.log("error", error);
      handleERROR({
        text: `Calendário do profissional não foi encontrado`,
        variant: "error",
      })();
    },
    onSuccess: (data) => {
      //armazenando valor de profissional

      const professionalsOptions = data.professionals.map(
        (professional: IProfessional) => ({
          _id: professional._id,
          name: professional.name,
        })
      );
      setChoosePro(professionalsOptions);
      const patientOptions = data.pacients.map((patient: IPatient) => ({
        _id: patient._id,
        name: patient.name,
      }));
      setChoosePatient(patientOptions);
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });

  useEffect(() => {
    allProfessionalsMutation.mutate();
  }, []);
  //  -------- BARRA DE PROGRESSO -------------

  const [progress, setProgress] = React.useState(0);

  //  --------------------------------- FEEDBACK DE ERROS --------------------------------
  const { enqueueSnackbar } = useSnackbar();

  interface IError {
    text: string;
    variant: VariantType;
  }
  const handleERROR =
    ({ text, variant }: IError) =>
    () => {
      // variant could be success, error, warning, info, or default
      enqueueSnackbar(`${text}`, {
        variant,
        className: "anchorOriginTopRight",
      });
    };

  const professionalMutation = useMutation({
    mutationFn: getProfessionalById,
    onError: (error: Error) => {
      console.log("error", error);
      handleERROR({
        text: `Calendário do profissional não foi encontrado`,
        variant: "error",
      })();
    },
    onSuccess: (data) => {
      //armazenando valor de profissional
      console.log(data);
      setProfessional(data);
    },
    onSettled: (data, error, variables, context) => {
      // Error or success... doesn't matter!
    },
  });

  const confirmEventMutation = useMutation({
    mutationFn: confirmEvent,
    onSuccess: (data) => {
      handleClose();
      if (data.status === 200 || data.status === 201 || data.status === 204) {
        setOpen(false);
      }
      window.location.reload();
    },
    onError: (error: Error) => {
      handleERROR({
        text: `Erro ao registrar o evento`,
        variant: "error",
      })();
    },
  });

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (value) {
      sessionStorage.setItem("dataConsulta", JSON.stringify(value));
    }
  }, [value]);

  useEffect(() => {
    console.log(pro);
    if (pro && pro._id) {
      professionalMutation.mutateAsync(pro._id);
    }
  }, [pro]);

  useEffect(() => {
    if (patient && patient._id) {
      const selectedPatient = allProfessionalsMutation.data?.pacients.find(
        (p) => p._id === patient._id
      );
      console.log(selectedPatient);
      if (selectedPatient) {
        setNomePaciente(selectedPatient.name);
        setEmailPaciente(selectedPatient.email);
        setTelefonePaciente(selectedPatient.phone_number);
      }
    }
  }, [patient]);

  async function handleAgendar() {
    if (patient && patient._id) {
      const selectedPatient: IPatient | undefined =
        allProfessionalsMutation.data?.pacients.find(
          (p) => p._id === patient._id
        );

      let startString: string | undefined = sessionStorage
        .getItem("dataConsulta")
        ?.toString();
      startString = startString ?? ""; // Define um valor padrão vazio caso startString seja undefined
      let formattedStartString = startString.replace(/"/g, ""); // Remove as aspas duplas
      const dataConsulta = formattedStartString.replace(/"/g, "").slice(0, 10);
      const horaConsulta = time.padStart(2, "0");
      const dataHoraConsulta = `${dataConsulta}T${horaConsulta}:00:00-03:00`;

      const body: IConfirmationEvent = {
        email: emailPaciente, //variaveis alteraveis pelo form
        name: nomePaciente, //variaveis alteraveis pelo form
        proId: professional?._id,
        start: dataHoraConsulta,
        patientId: selectedPatient?._id,
        marketplace_id: selectedPatient?.marketplaceId.toString(),
        status: "confirmado",
        phone: telefonePaciente, //variaveis alteraveis pelo form
      };
      const resp = confirmEventMutation.mutateAsync(body);

      // caso ok disparar o evento para confirmação com os dados e horario no celular whasy
    }
  }
  // ---------------------- BARRA DE PROGESSO DOS STAPS -------------------
  useEffect(() => {
    const calculateProgress = (step: number) => {
      switch (step) {
        case 0:
          return 0;
        case 1:
          return 50;
        case 2:
          return 100;
        default:
          return 0;
      }
    };

    const updateProgress = (step: number) => {
      const newProgress = calculateProgress(step);
      setProgress(newProgress);
    };

    updateProgress(step); // Atualiza o progresso inicial com base no valor atual do step
  }, [step]); // Executa o efeito sempre que o valor do step mudar
  // -------------------- calendario config -------------------------
  const dayOfWeekTranslation: { [key: string]: string } = {
    Mon: "Seg",
    Tue: "Ter",
    Wed: "Qua",
    Thu: "Qui",
    Fri: "Sex",
    Sat: "Sáb",
    Sun: "Dom",
  };
  return (
    <Box
      sx={{
        bgcolor: "#EBEBEB", //CINZA
      }}
    >
      <Box sx={{ p: { xs: 0, lg: 2 } }} height="100vh" overflow="scroll">
        <>
          <Box
            display="flex"
            zIndex={1}
            sx={{
              bgcolor: { xs: "white", lg: "transparent" },
              position: { xs: "sticky", lg: "relative" },
              paddingTop: "1rem",
              paddingLeft: "2rem",
              paddingRight: "2rem",
              paddingBottom: "1rem",
              justifyContent: { xs: "center", lg: "start" },
              alignItems: { xs: "center", lg: "start" },
              flexDirection: { xs: "column", lg: "row" },
              gap: { xs: 2, lg: 0 },
              mt: { xs: 3, lg: 0 },
            }}
            top={0}
          >
            <img
              className="logo"
              style={{
                width: "120px",
                height: "auto",
                marginTop: "10",
              }}
              src={logo}
              alt="Logo Psi do futuro"
            />

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexDirection="column"
              height="100%"
              width="100%"
              gap={1}
              sx={{ marginRight: { xs: 0, lg: "120px" } }}
            >
              <Typography
                variant="h4"
                sx={{ fontSize: { xs: "14px", lg: "16px" } }}
                // color="#242424"
                color="#486522"
              >
                Agendamento de sessão{" "}
                <strong
                  style={{
                    color: "#486522",
                    fontSize: "18px",
                    fontWeight: 700,
                  }}
                >
                  {nomePaciente}
                </strong>
              </Typography>
              <Typography
                variant="h5"
                color="#486522"
                sx={{
                  fontSize: { xs: "20px" },
                  textAlign: { xs: "center" },
                  mt: { xs: 1, lg: 0 },
                  display: { xs: step === 0 ? "block" : "none" },
                }}
                // color="#242424"
              >
                Selecione o melhor dia e horário para você ser atendida(o) por
                um de nossos psicólogos
              </Typography>
              {step > 0 && value && (
                <>
                  {" "}
                  <Typography
                    variant="h5"
                    textTransform="capitalize"
                    fontWeight={700}
                    color="#486522"
                  >
                    {value.locale("pt-br").format("dddd")}
                  </Typography>
                  <Box
                    mb={2}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    //bgcolor="#0066e6"
                    //bgcolor="#637E3B"
                    // bgcolor="#BCC6ab"
                    bgcolor="#36822E"
                    padding={1}
                    paddingX={2}
                    borderRadius={2}
                    boxShadow="rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset;"
                  >
                    <Box display="flex" gap={1} m={1}>
                      <Typography color="#fff" fontWeight={600}>
                        {value.locale("pt-br").format("DD [de] MMMM [de] YYYY")}{" "}
                      </Typography>
                      {time && (
                        <Typography color="#fff" fontWeight={600}>
                          {" "}
                          às {formatarHora(parseInt(time))}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  {!time && isMobile && (
                    <Typography color="#486522" variant="h5">
                      Selecione horário
                    </Typography>
                  )}
                </>
              )}

              {/* ------------ [ MOBILE ] : SETAS DIREITA ESQUEDA ----------------- */}
              {step > 0 && (
                <Box
                  width="100%"
                  display={isMobile ? "flex" : "none"}
                  sx={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    flexDirection: "row",
                    marginX: 1,
                  }}
                >
                  <Button
                    onClick={() => setStep(step - 1)}
                    variant="text"
                    startIcon={<KeyboardBackspaceIcon />}
                  >
                    Voltar
                  </Button>
                  {step == 1 && time && (
                    <Button
                      onClick={() => setStep(2)}
                      variant="text"
                      endIcon={<ArrowRightAltIcon />}
                    >
                      Continuar
                    </Button>
                  )}
                </Box>
              )}
            </Box>
          </Box>

          {/* ------------- STEPS ----------------- */}
          <Box
            justifyContent="space-evenly"
            alignItems="center"
            sx={{
              display: { xs: "none", lg: "flex" },
              flexDirection: "column",
            }}
          >
            <Box
              width="100%"
              justifyContent="space-evenly"
              alignItems="center"
              sx={{
                display: { xs: "none", lg: "flex" },
              }}
            >
              <img
                className="logo"
                style={{ maxWidth: "100px", zIndex: 1 }}
                src={etapa1}
                alt="Logo Psi do futuro"
              />
              <img
                className="logo"
                style={{ maxWidth: "120px", zIndex: 1 }}
                src={etapa2}
                alt="Logo Psi do futuro"
              />
              <img
                className="logo"
                style={{ maxWidth: "120px", zIndex: 1 }}
                src={etapa3}
                alt="Logo Psi do futuro"
              />
            </Box>
            <Box height="100%" sx={{ width: "55%", zIndex: 0, mt: -7, mb: 10 }}>
              <LinearProgress variant="determinate" value={progress} />
            </Box>
          </Box>
          {/* ---------------------------------------------- PAINEIS --------------------------------------------------------------- */}
          <Box m={2} display="flex" gap={2} justifyContent="center">
            {" "}
            <Autocomplete
              value={pro}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                newValue: IProfessionalChoose | null
              ) => {
                if (newValue) {
                  setPro(newValue);
                }
              }}
              inputValue={proInput}
              onInputChange={(event, newInputValue) => {
                setProInput(newInputValue);
              }}
              id="profissionais"
              options={choosePro}
              sx={{ width: 300 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Psicologo(a)" />
              )}
            />
            <Autocomplete
              value={patient}
              onChange={(
                event: React.SyntheticEvent<Element, Event>,
                newValue: IPatientChoose | null
              ) => {
                if (newValue) {
                  setPatient(newValue);
                }
              }}
              inputValue={patientInput}
              onInputChange={(event, newInputValue) => {
                setPatientInput(newInputValue);
              }}
              id="pacientes"
              options={choosePatient}
              sx={{ width: 300 }}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField {...params} label="Pacientes" />
              )}
            />
          </Box>
          <Grid container sx={{ justifyContent: "center", maxHeight: "400px" }}>
            {/* --------------------------------------------------- PAINEL DE CALENDÁRIO -------------------------------------------------------- */}

            <Grid
              item
              xs={12}
              lg={5}
              bgcolor="white"
              borderRadius={2}
              boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px;"
              sx={{
                display: {
                  xs: isMobile && step === 0 ? "block" : "none",
                  lg: "block",
                },
                mt: { xs: 6, lg: 0 },
              }}
            >
              {" "}
              {/* --------------------------------------- nome e foto profissional ----------------------- */}
              <Box ml={5} display="flex" justifyContent="flex-start" pt={2}>
                <Box display="flex" gap={2} alignItems="center">
                  <Avatar
                    sx={{ width: 40, height: 40 }}
                    src={`data:${professional?.img?.mimetype};base64,${professional?.img?.data}`}
                    alt="Perfil do psicologo"
                  />

                  <Typography color="#486522">
                    <span style={{ fontWeight: 600 }}>
                      {professional?.displayName}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="flex-start"
                flexDirection="row"
              >
                <Box>
                  {professional && (
                    <HorarioAtendimentoTable professional={professional} />
                  )}
                </Box>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="pt-br"
                  localeText={
                    ptBR.components.MuiLocalizationProvider.defaultProps
                      .localeText
                  }
                >
                  <DateCalendar
                    dayOfWeekFormatter={(day: string) =>
                      dayOfWeekTranslation[day]
                    }
                    value={value}
                    onChange={(newValue) => {
                      sessionStorage.setItem(
                        "dataConsulta",
                        JSON.stringify(newValue)
                      );
                      setValue(newValue);
                      setStep(1);
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            {/* ---------------------------------------------- PAINEL DE HORÁRIOS --------------------------------------------------------------- */}

            <Grid
              sx={{
                display: {
                  xs: isMobile && step === 1 ? "block" : "none",
                  lg: "block",
                  maxHeight: "400px",
                  height: "400px",
                  overflowX: "scroll",
                  overflowY: "hidden",
                },
                marginTop: isMobile ? 2 : 0,
              }}
              item
              xs={12}
              lg={2}
              ml={isMobile ? 0 : 4}
            >
              <TimeCardList
                key={uuidv4()}
                id={professional?._id}
                setStep={setStep}
                setTime={setTime}
                time={time}
                step={step}
              />
            </Grid>

            {/* ---------------------------------------------- PAINEL DE CONFIRMAÇÃO --------------------------------------------------------------- */}

            <Formik
              validationSchema={validationSchema}
              initialValues={{
                nomePaciente,
                emailPaciente,
                telefonePaciente,
              }}
              onSubmit={handleAgendar}
            >
              {({ values, handleSubmit, handleChange, errors, touched }) => (
                <Grid
                  item
                  xs={12}
                  lg={4}
                  sx={{
                    display: {
                      xs: isMobile && step === 2 ? "block" : "none",
                      lg: "block",
                    },
                  }}
                >
                  <Box
                    px={2}
                    sx={{
                      ml: { xs: 0, lg: 2 },

                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      bgcolor={"white"}
                      height="100%"
                      minHeight={isMobile ? "auto" : "400px"}
                      marginTop={isMobile ? 2 : 0}
                      width="90%"
                      boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px;"
                      borderRadius={2}
                      p={2}
                      gap={2}
                    >
                      {" "}
                      <Box display="flex" width="100%" alignContent="center">
                        <Typography
                          fontWeight={700}
                          textAlign="left"
                          color="#486522"
                        >
                          Confirme os dados do paciente:
                        </Typography>
                      </Box>
                      <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        disabled={step > 1 ? false : true}
                        placeholder={nomePaciente}
                        name="nomePaciente"
                        value={values.nomePaciente}
                        onChange={(event) => {
                          setNomePaciente(event.target.value);
                          handleChange(event);
                        }}
                        error={
                          touched.nomePaciente && errors.nomePaciente
                            ? true
                            : false
                        }
                        helperText={touched.nomePaciente && errors.nomePaciente}
                      />
                      <TextField
                        disabled={step > 1 ? false : true}
                        sx={{ mt: 2 }}
                        fullWidth
                        placeholder={emailPaciente}
                        name="emailPaciente"
                        value={values.emailPaciente}
                        onChange={(event) => {
                          setEmailPaciente(event.target.value);
                          handleChange(event);
                        }}
                        error={
                          touched.emailPaciente && errors.emailPaciente
                            ? true
                            : false
                        }
                        helperText={
                          touched.emailPaciente && errors.emailPaciente
                            ? errors.emailPaciente
                            : ""
                        }
                      />
                      <TextField
                        disabled={step > 1 ? false : true}
                        sx={{ mt: 2 }}
                        fullWidth
                        placeholder={telefonePaciente}
                        name="telefonePaciente"
                        value={values.telefonePaciente}
                        onChange={(event) => {
                          setTelefonePaciente(event.target.value);
                          handleChange(event);
                        }}
                        error={
                          touched.telefonePaciente && errors.telefonePaciente
                            ? true
                            : false
                        }
                        helperText={
                          touched.telefonePaciente && errors.telefonePaciente
                            ? errors.telefonePaciente
                            : ""
                        }
                      />
                      <Button
                        type="submit"
                        onClick={handleAgendar}
                        fullWidth
                        sx={{
                          mt: { xs: 4, lg: 6 },
                          textTransform: "capitalize",
                          fontSize: "14px",
                        }}
                        variant="contained"
                        disabled={step > 1 ? false : true}
                      >
                        Confirmar
                      </Button>
                      {confirmEventMutation.isLoading && (
                        <Stack sx={{ width: "100%", color: "grey.500", mb: 2 }}>
                          <LinearProgress color="inherit" />
                        </Stack>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}
            </Formik>
          </Grid>
        </>

        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Loading />
        </Dialog>
      </Box>
    </Box>
  );
}
