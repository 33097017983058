import React, { useState } from "react";
import {
  Dialog,
  DialogContentText,
  DialogTitle,
  Grid,
  Button,
  DialogActions,
} from "@mui/material";
import { IProfessional } from "../../api/types/professionals";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";
import { ptBR } from "../../utils/PortuguesCalendar/ptBR";
import {
  IAvalibleEvent,
  getEventsByDay,
  reschedulingEventInstance,
} from "../../api/Agenda";
import { dayOfWeekTranslation } from "../../utils/calendarConfig";
import TimeCardList from "./TimeCardList";
import Loading from "../Loading";
import { useMutation } from "@tanstack/react-query";

interface IProp {
  open: boolean;
  setOpen: (value: boolean) => void;
  idProfessional: string;
  idSessao?: string;
}
export default function ModalRescheduling({
  open,
  setOpen,
  idProfessional,
  idSessao,
}: IProp) {
  const handleClose = () => {
    setOpen(false);
  };
  //variaveis para logica de reagendamento

  const [idPatient, setIdPatient] = useState("");

  const [timeSelected, setTimeSelected] = useState<string | null>(null);
  const [selectedPsi, setSelectedPsi] = useState<IProfessional>();
  const [professional, setProfessional] = useState<IProfessional>();

  const [dateSelected, setDateSelected] = useState<Dayjs | null>(dayjs());
  const [dayList, setDayList] = useState<IAvalibleEvent[]>([]);

  const mutationAvalibleEvents = useMutation(getEventsByDay, {
    onSuccess: (data: IAvalibleEvent[]) => {
      setDayList(data);
    },
    retry: 0,
  });

  React.useEffect(() => {
    if (dateSelected) {
      const dateString = dateSelected.toISOString();
      const dataConsulta = dateString.slice(0, 10);

      setDayList([]); //clean horarios disponiveis antes de pesquisar
      mutationAvalibleEvents.mutate({
        id: idProfessional,
        day: dataConsulta,
      });
    }
  }, [dateSelected]);

  const handleDateChange = (newValue: Dayjs | null) => {
    if (newValue) {
      const dayjsDate = dayjs(newValue);
      setDateSelected(dayjsDate);
      console.log("dateSelected", dateSelected);
    } else {
      setDateSelected(null);
      console.log("dateSelected", dateSelected);
    }
  };
  const reschedulingMutation = useMutation(reschedulingEventInstance, {
    onSuccess: (data) => {
      console.log("data :", data);
      // handleClose();
      // window.location.reload();
    },
    retry: 0,
  });

  function handleRescheduling() {
    console.log(" handleRescheduling");
    if (idSessao && dateSelected && timeSelected) {
      const dateString = dateSelected.toISOString();
      const dataConsulta = dateString.slice(0, 10);
      const timeFormatted =
        timeSelected.length === 1 ? `0${timeSelected}` : timeSelected;
      const dataHoraConsulta = `${dataConsulta}T${timeFormatted}:00:00-03:00`;

      console.log(" dataHoraConsulta:: ", dataHoraConsulta);
      reschedulingMutation.mutate({
        eventId: idSessao,
        proId: idProfessional,
        start: dataHoraConsulta,
      });
    }
  }

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Reagendamento"}</DialogTitle>
        <DialogContentText>
          <DialogContentText id="alert-dialog-description">
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              adapterLocale="pt-br"
              localeText={
                ptBR.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <DateCalendar
                dayOfWeekFormatter={(day: string) => dayOfWeekTranslation[day]}
                value={dateSelected}
                onChange={handleDateChange}
              />
            </LocalizationProvider>

            {dayList && (
              <Grid
                sx={{
                  display: {
                    lg: "block",

                    overflowX: "scroll",
                    overflowY: "hidden",
                  },
                }}
                item
                xs={12}
              >
                <TimeCardList
                  setTime={setTimeSelected}
                  time={timeSelected}
                  id={idProfessional}
                  dayList={dayList}
                />
              </Grid>
            )}
          </DialogContentText>
        </DialogContentText>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          {reschedulingMutation.isLoading && <Loading />}
          <Button
            onClick={handleRescheduling}
            autoFocus
            disabled={timeSelected ? false : true}
          >
            Reagendar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
