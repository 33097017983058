import { styled } from "@mui/material/styles";
import Image from "../../Assets/background-lines.png";

export const NavWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-start",
  padding: ".5rem",
  paddingLeft: "3em",
  paddingRight: "3em",

  margin: 0,
  [theme.breakpoints.down("md")]: {
    marginTop: theme.spacing(0),
    paddingLeft: ".8em",
    paddingRight: ".8em",
  },
}));

export const NavMenu = styled("div")(({ theme }) => ({
  display: "none",
  padding: 0,
  [theme.breakpoints.down("md")]: {
    display: "flex",
    padding: theme.spacing(1),
  },
}));

export const NavLinks = styled("div")(({ theme }) => ({
  display: "none",
  gap: "2rem",
  [theme.breakpoints.up("md")]: {
    display: "flex",
    padding: theme.spacing(1),
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export const NavLink = styled("a")`
  color: rgb(69, 107, 38);
  &:hover {
    color: #637d3b;
  }
  &:focus {
    color: "#496522";
  }
`;
