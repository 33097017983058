import { Box } from "@mui/material";
import React from "react";

const ComingSoon = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignContent="center"
      alignItems="center"
      height="100vh"
      fontWeight={900}
      fontSize={24}
      fontStyle="italic"
    >
      <div>Em Breve!</div>
    </Box>
  );
};

export default ComingSoon;
