
import http from "./http";

export interface IChatOptions {
    _id: string,
    text: string
}
export async function getChatOptions (): Promise<IChatOptions []> {
    const response = await http.get("/api/chat");
    return response.data.data
}
