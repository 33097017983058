import {
  IReschedulingInstance,
  IReserveEvent,
  IReservEventAnswer,
} from "./types/agenda";
import moment from "moment";
import http from "./http";
import { ChangeTherapy } from "../pages/appointment/type";

export async function GetEvents() {
  const response = await http.get("/api/");
  return response.data;
}
export interface IEvent {
  start: string;
  end: string;
  client: IClient;
}

export interface IClient {
  email: string;
  name: string;
}

export interface IAvalibleEvent {
  id?: string;
  summary: string;
  start: string;
  end: string;
}

export interface Event {
  summary: string;
  start: string;
  end: string;
}

export async function CreateEvent(event: IEvent) {
  const professionalId = "63bb4e20a2940a4c369fbc00";
  console.log(event);
  const response = await http.post(
    `/api/calendar/63bb4e20a2940a4c369fbc00`,
    event
  );
  return response.data;
}

export async function GetCalendars() {
  const response = await http.get("/api/");
  return response.data;
}

export interface IWeekRequest {
  id?: string;
  year: string;
  weekStart: string;
  weekEnd: string;
}

export interface IEventRequest {
  proId: string;
  eventId: string;
}

// REFATORACAO 2.0
// //retorno:
// summary: 'Disponível',
// start: format(start, 'dd/MM/yyyy, HH:mm:ss'),
// end: format(end, 'dd/MM/yyyy, HH:mm:ss'),
export async function getWeekEvents(
  req: IWeekRequest
): Promise<IAvalibleEvent[]> {
  const response = await http.get(
    `/api/events/${req.id}?w=${req.year}-${req.weekStart},${req.year}-${req.weekEnd}`
  );
  return response.data.data;
}

interface IEventsByDayRequest {
  day: string; //formato 2023-06-15
  id?: string;
}

export async function getEventsByDay(
  req: IEventsByDayRequest
): Promise<IAvalibleEvent[]> {
  const response = await http.get(`/api/events/${req.id}?d=${req.day}`);
  return response.data.data;
}

//Email confirmado chama essa função para reservar evento. versão 1.0
//versão 2.0 evento será criado, e escrito como reservado com o horario e data e dados do usuario são necessarios pra iso
export async function ReserveEvent(
  data: IReserveEvent
): Promise<IReservEventAnswer[]> {
  const response = await http.post("/api/events", data);
  return response.data.data;
}

export interface IRescheduling {
  eventId?: string;
  start?: string;
  bookingId?: string;
  proId?: string;
  frequency?: ChangeTherapy;
}

interface IEventByDay {
  prodId?: string;
  date: string;
}

//POST
export interface IReschedulingResponse {
  data: [];
  message: string;
}
export async function reschedulingEvent(data: IRescheduling) {
  const response = await http
    .post("/api/events/reschedule", data)
    .then((response) => {
      if (response.status === 200) {
        console.log(`Successful request, status code:  ${response.status}`);
        return response.data.message;
      }
      if (response.status === 201) {
        console.log(`Successful request, status code:  ${response.status}`);
        return response.data.message;
      }
      if (response.status === 204) {
        console.log(`Successful request, status code:  ${response.status}`);
        return response.data.message;
      } else {
        console.log(`Request failed, status code: ${response.status}`);
        return response.status;
      }
    })
    .catch((error) => {
      console.error("Request failed, error:", error);
      return error;
    });
  return response.status;
}

export async function reschedulingEventInstance(data: IReschedulingInstance) {
  console.log("data::: ", data);
  const response = await http
    .post("/api/events/rescheduleInstance", data)
    .then((response) => {
      if (response.status === 200) {
        console.log(`Successful request, status code:  ${response.status}`);
        return response.data.message;
      }
      if (response.status === 201) {
        console.log(`Successful request, status code:  ${response.status}`);
        return response.data.message;
      }
      if (response.status === 204) {
        console.log(`Successful request, status code:  ${response.status}`);
        return response.data.message;
      } else {
        console.log(`Request failed, status code: ${response.status}`);
        return response.status;
      }
    })
    .catch((error) => {
      console.error("Request failed, error:", error);
      return error;
    });
  return response.status;
}

export async function getWeekEventByDay(
  data: IEventByDay
): Promise<IAvalibleEvent[]> {
  console.log("aqui");
  const response = await http.get(`/api/events/${data.prodId}?d=${data.date}`); //2023-02-8

  const currentYear = moment().format("YYYY");
  const filteredData = response.data.data.filter((event: { start: any }) => {
    return moment(event.start).format("YYYY") === currentYear;
  });
  return filteredData;
}

export async function getEventData(data: IEventRequest): Promise<Event> {
  const response = await http.get(`/api/events/${data.proId}/${data.eventId}`);
  return response.data.data;
}
