import { Box, Fade, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { paymentMethod } from "../../pages/private/InternalControl/utils/status";
import { ILogRow } from "../../api/types/logs";
import { v4 as uuidv4 } from "uuid";
import { Subscription } from "../../api/types/patient";
interface IDetailsProps {
  activeSubscriptions: Subscription[];
  inactiveSubscriptions: Subscription[];
  latestApprovedPaymentDate: string | null;
  pastdueSubscriptions?: Subscription[];
  subscription: Subscription[];
  row: ILogRow;
  open: boolean;
}
export default function SubscriptionDetails({
  activeSubscriptions,
  inactiveSubscriptions,
  pastdueSubscriptions,
  latestApprovedPaymentDate,
  subscription,
  open,
  row,
}: IDetailsProps) {
  function formatDate(dateString: string) {
    const date = new Date(dateString);
    return date.toLocaleDateString("pt-BR");
  }
  const [isProfuct, setIsProduct] = useState(false);
  useEffect(() => {
    if (subscription) {
      if (subscription.length == 0) setIsProduct(true);
    }
  }, []);

  return (
    <Paper sx={{ p: 1, display: open ? "block" : "none" }} elevation={4}>
      <Fade in={open}>
        <Box>
          {activeSubscriptions.length > 0 && (
            <Box key={uuidv4()} display="flex" flexDirection="column">
              <Typography key={uuidv4()} variant="caption" fontWeight={600}>
                {" "}
                Ativas:{" "}
              </Typography>
              {activeSubscriptions.map((subscription) => (
                <Box display="flex" flexDirection="column" key={uuidv4()}>
                  <Typography variant="caption" key={uuidv4()}>
                    Código:{subscription.subscription_code}
                  </Typography>
                  <Typography variant="caption" key={uuidv4()}>
                    Próximo pagamento: {formatDate(subscription.next_cycle_at)}
                  </Typography>
                  <Typography variant="caption" key={uuidv4()}>
                    Pagamento atual:
                    {latestApprovedPaymentDate &&
                      formatDate(latestApprovedPaymentDate)}
                  </Typography>
                </Box>
              ))}
            </Box>
          )}
          {inactiveSubscriptions.length > 0 && (
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" fontWeight={600}>
                Canceladas:{" "}
              </Typography>
              {inactiveSubscriptions.map((subscription) => (
                <Typography color="red" variant="caption" key={uuidv4()}>
                  Código:{subscription.subscription_code}
                </Typography>
              ))}
            </Box>
          )}
          {pastdueSubscriptions && pastdueSubscriptions.length > 0 && (
            <Box display="flex" flexDirection="column">
              <Typography variant="caption" fontWeight={600}>
                Atrasada:{" "}
              </Typography>
              {pastdueSubscriptions.map((subscription) => (
                <Typography color="red" variant="caption" key={uuidv4()}>
                  Código:{subscription.subscription_code}
                </Typography>
              ))}
            </Box>
          )}
          {isProfuct && (
            <Typography variant="caption">
              Data de pagamento: {latestApprovedPaymentDate?.substring(0, 10)}
            </Typography>
          )}
        </Box>
      </Fade>
    </Paper>
  );
}
