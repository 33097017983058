import React, { useEffect, useState } from "react";
import {
  Box,
  Chip,
  IconButton,
  Switch,
  TableCell,
  Typography,
  Tooltip,
} from "@mui/material";
import ToolsAgenda from "./ToolsAgenda";
import { typeSession } from "../../pages/private/InternalControl/utils/status";
import { ILogRow, Transaction } from "../../api/types/logs";
import { v4 as uuidv4 } from "uuid";
import SubscriptionDetails from "./SubscriptionDetails";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import NotesIcon from "@mui/icons-material/Notes";
import { Swap } from "@phosphor-icons/react";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";
import ModalRescheduling from "./ModalRescheduling";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import { useNavigate } from "react-router-dom";
import { Subscription } from "../../api/types/patient";

export const statusPaymentColors: { [key: string]: string } = {
  Pago: "#4caf50", //"Sessão Inicial Pago": "info",
  Aguardando: "#ffeb3b",
  undefined: "#ccc",
  Atrasada: "#f59393",
  Produto: "#4caf50",
  Cancelada: "#d32f2f80",
};
interface ILogRowProps {
  row: ILogRow;
  keyDetails: number;
  date: Dayjs | null;
}
export default function LogRow({ row, keyDetails, date }: ILogRowProps) {
  const navigate = useNavigate();

  const [subscription, setSubscription] = useState<Subscription[]>(
    row.patient.subscriptions
  );
  const [transactions, setTransactions] = useState<Transaction[] | undefined>(
    row.patient.history
  );
  const [activeSubscriptions, setActiveSubscriptions] = useState<
    Subscription[] | []
  >([]);
  const [inactiveSubscriptions, setInactiveSubscriptions] = useState<
    Subscription[] | []
  >([]);
  const [pastdueSubscriptions, setPastdueSubscriptions] = useState<
    Subscription[] | []
  >([]);

  const [openDetails, setOpenDetails] = useState<{ [key: number]: boolean }>(
    {}
  );
  const [open, setOpen] = React.useState(false);
  const [lastPaymentDate, setLastPaymentDate] =
    React.useState<string | null>("");

  const formattedDate = dayjs(date).format("YYYY-MM-DD");

  const foundSessao = row.patient.sessoes.find((sessao) =>
    sessao.data.startsWith(formattedDate)
  );
  const getActiveSubscriptions = (
    Subscription: Subscription[]
  ): Subscription[] => {
    return Subscription.filter(
      (subscription) => subscription.last_status == "active"
    );
  };
  const getPastdueSubscriptions = (
    Subscription: Subscription[]
  ): Subscription[] => {
    return Subscription.filter(
      (subscription) => subscription.last_status == "pastdue"
    );
  };
  const getInactiveSubscriptions = (
    Subscription: Subscription[]
  ): Subscription[] => {
    return Subscription.filter(
      (subscription) => subscription.last_status == "canceled"
    );
  };
  const getLatestApprovedPaymentDate = (data: Transaction[]): string | null => {
    const approvedPayments = data.filter(
      (transaction) =>
        transaction.status === "approved" &&
        transaction.payment &&
        transaction.payment.processing_times &&
        transaction.payment.processing_times.finished_at
    );

    const sortedApprovedPayments = approvedPayments.sort(
      (a, b) =>
        new Date(b.payment.processing_times.finished_at).getTime() -
        new Date(a.payment.processing_times.finished_at).getTime()
    );

    return sortedApprovedPayments.length > 0
      ? sortedApprovedPayments[0].payment.processing_times.finished_at
      : null;
  };

  useEffect(() => {
    if (transactions) {
      const latestApprovedPaymentDate =
        getLatestApprovedPaymentDate(transactions);
      setLastPaymentDate(latestApprovedPaymentDate);
    }
  }, [transactions]);

  useEffect(() => {
    if (subscription) {
      setActiveSubscriptions(getActiveSubscriptions(subscription));
      setPastdueSubscriptions(getPastdueSubscriptions(subscription));
      setInactiveSubscriptions(getInactiveSubscriptions(subscription));
    }
  }, [subscription]);

  const getStatusByDate = (
    lastPaymentDate: string | null,
    nextPaymentDate: string
  ): string | undefined => {
    if (lastPaymentDate) {
      const parsedLastPaymentDate = new Date(lastPaymentDate);
      const parsedNextPaymentDate = new Date(nextPaymentDate);

      if (foundSessao) {
        const parsedSessionDate = new Date(foundSessao.data.toString());
        const isLastPaymentBeforeSession =
          parsedLastPaymentDate < parsedSessionDate;
        const isSameDayAsNextPayment =
          parsedSessionDate.toDateString() ===
          parsedNextPaymentDate.toDateString();

        if (isSameDayAsNextPayment && isLastPaymentBeforeSession) {
          // proximo pagamento é no mesmo dia da sessao e nao houve pagamento ainda
          return "Aguardando";
        }

        if (!lastPaymentDate || parsedSessionDate < parsedLastPaymentDate) {
          return "Aguardando";
        }

        if (nextPaymentDate && parsedSessionDate >= parsedNextPaymentDate) {
          return "Aguardando";
        }
      }
      return "Pago";
    }
  };

  const handleNavigate = (id: string | undefined) => {
    navigate(
      `/clinica/detalhes-paciente/${id}/${row.proName}/${row.patient.id_psicologo}`
    );
  };

  const renderSubscriptionChip = () => {
    if (activeSubscriptions.length > 0) {
      return activeSubscriptions.map((subscription) => (
        <Chip
          key={uuidv4()}
          label={
            getStatusByDate(lastPaymentDate, subscription.next_cycle_at) ?? "--"
          }
          style={{
            backgroundColor:
              statusPaymentColors[
                getStatusByDate(lastPaymentDate, subscription.next_cycle_at) ??
                  "--"
              ],
          }}
        />
      ));
    } else if (pastdueSubscriptions.length > 0) {
      return (
        <Chip
          label={"Atrasada"}
          key={uuidv4()}
          style={{
            backgroundColor: statusPaymentColors["Atrasada"],
          }}
        />
      );
    } else if (inactiveSubscriptions.length > 0) {
      return (
        <Chip
          label={"Cancelada"}
          key={uuidv4()}
          style={{
            backgroundColor: statusPaymentColors["Cancelada"],
          }}
        />
      );
    } else if (
      row.patient &&
      row.patient.subscriptions &&
      row.patient.subscriptions.length === 0 &&
      lastPaymentDate
    ) {
      return (
        <Tooltip
          title={`último pagamento: ${lastPaymentDate.substring(0, 10)}`}
        >
          <Chip
            label={"Pago"}
            key={uuidv4()}
            style={{
              backgroundColor: statusPaymentColors["Produto"],
            }}
          />
        </Tooltip>
      );
    } else {
      return (
        <Chip
          label={"Aguardando"}
          key={uuidv4()}
          style={{
            backgroundColor: statusPaymentColors["Aguardando"],
          }}
        />
      );
    }
  };

  return (
    <>
      {/*----------------------------------------- agendamento -------------------------------------*/}
      <TableCell>
        <Typography variant="caption">
          {foundSessao && foundSessao.data.substring(0, 10)}
        </Typography>
        <IconButton onClick={() => setOpen(true)}>
          <Tooltip title="Reagendar Sessão">
            <Swap size={20} />
          </Tooltip>
        </IconButton>
      </TableCell>
      {/*----------------------------------------- horario -----------------------------------------*/}
      <TableCell sx={{ maxWidth: "20px" }}>
        <Typography variant="caption">
          {foundSessao && foundSessao.hora}
        </Typography>
      </TableCell>
      {/*----------------------------------------- paciente -----------------------------------------*/}
      <TableCell>
        <IconButton onClick={() => handleNavigate(row.patient._id)}>
          <Tooltip title="Detalhes do paciente">
            <ContactPageIcon />
          </Tooltip>
        </IconButton>
        <Typography variant="caption">{row.patient.name}</Typography>
      </TableCell>
      {/*----------------------------------------- psicologa -----------------------------------------*/}
      <TableCell>
        <Typography variant="caption">{row.proName}</Typography>
      </TableCell>
      {/*----------------------------------------- tipo de assinatura --------------------------------*/}
      <TableCell>
        {" "}
        <Typography variant="caption">
          {typeSession[row.patient.tipo_sessao]}
        </Typography>
      </TableCell>
      {/*----------------------------------------- Switch assinatura -----------------------------------------*/}

      <TableCell align="left">
        <Box borderRadius="1rem" gap={2} display="flex">
          {activeSubscriptions?.map((subscription) => (
            <Switch key={uuidv4()} defaultChecked />
          ))}
          {activeSubscriptions.length === 0 && pastdueSubscriptions && (
            <Switch key={uuidv4()} defaultChecked color="error" />
          )}

          {!activeSubscriptions && inactiveSubscriptions && (
            <Switch key={uuidv4()} defaultChecked color="error" />
          )}
          <IconButton
            onClick={() => {
              setOpenDetails((prevState) => ({
                ...prevState,
                [keyDetails]: !prevState[keyDetails], // Use patientId as the identifier for each row
              }));
            }}
          >
            <Tooltip title="Detalhes de assinaturas">
              {openDetails[keyDetails] ? <MenuOpenIcon /> : <NotesIcon />}
            </Tooltip>
          </IconButton>
          <SubscriptionDetails
            subscription={subscription}
            latestApprovedPaymentDate={lastPaymentDate}
            open={openDetails[keyDetails]} // Passa o estado de abertura/fechamento para o componente PaymentDetails
            activeSubscriptions={activeSubscriptions}
            inactiveSubscriptions={inactiveSubscriptions}
            pastdueSubscriptions={pastdueSubscriptions}
            row={row}
          />
        </Box>
      </TableCell>
      {/*----------------------------------------- status pagamento -----------------------------------------*/}
      <TableCell>{renderSubscriptionChip()}</TableCell>

      {/* ----------------------------------------- ferramenta ----------------------------------------- */}
      <TableCell>
        <ToolsAgenda
          pastdueSubscriptions={pastdueSubscriptions[0] ?? null}
          row={row}
          activeSubscriptions={activeSubscriptions[0]}
        />
      </TableCell>
      <TableCell>
        {activeSubscriptions.length > 1 && (
          <Typography color="red" variant="caption" fontWeight={600}>
            Duas assinaturas ativas
          </Typography>
        )}
      </TableCell>
      {/* ------------------ LOGICA DE MODAL REAGENDAMENTO ------------------- */}
      {/* ------------------ precisa adicionar um check box no log para reagendar todos os proximos agendamentos ------------------- */}
      <ModalRescheduling
        open={open}
        setOpen={setOpen}
        idProfessional={row.patient.id_psicologo}
        idSessao={foundSessao?.idEvent}
      />
    </>
  );
}
