import Lottie from "react-lottie";
import NavBar from "../../components/navBar/NavBar";
import { CenterWrapper } from "./NotFound.styles";
import { Typography } from "@mui/material";

export default function NotFound() {
  const animationData = require("../../lotties/84223-404-error-page-not-found.json");

  return (
    <>
      <NavBar />
      <CenterWrapper>
        <Lottie
          style={{ margin: 0, paddingTop: "3rem" }}
          height={"auto"}
          width={"50%"}
          options={{ animationData }}
        />
        <div>
          <Typography variant="h5">Página não encontrada</Typography>
        </div>
      </CenterWrapper>
    </>
  );
}
