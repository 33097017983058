import React, { useState, useEffect, memo } from "react";
import { Box, Button, Slide, ToggleButton, Typography } from "@mui/material";

import moment from "moment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";
import { IAvalibleEvent } from "../../api/Agenda";
import { formatarHora } from "../../utils/timeFormat";

export interface IAgendaConfirmation {
  id?: string;
  setStep?: (value: number) => void;
  time: string | null;
  setTime: (value: string) => void;
  step?: number;
  dayList: IAvalibleEvent[];
}
//nova logica para garantir data correr vindo do backend
function TimeCardList({ id, setTime, time, dayList }: IAgendaConfirmation) {
  const dataConsultaString = sessionStorage.getItem("dateSelected") || "";
  const [dataConsulta, setDataConsulta] =
    useState<string | null>(dataConsultaString);

  const [showConfirmButton, setShowConfirmButton] =
    useState<number | null>(null);

  useEffect(() => {
    if (time) {
      console.log(time);
    }
  }, [time]);

  //Atualiza selectedTime e IAvalibleEvent
  const handleSelectedTime = () => {
    if (showConfirmButton) {
      setTime(showConfirmButton.toString());
      // setStep(2); // controle de amostra de painel para versão mobile
      console.log("showConfirmButton :::", showConfirmButton);
    }
  };

  // if (mutationAvalibleEvents.isLoading) {
  //   return <Loading />;
  // }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      alignContent="center"
      gap={2}
    >
      <Typography>Selecione o horário: </Typography>
      <Box
        boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px;"
        maxHeight="100%"
        width="100%"
        p={time ? 2 : 0}
        borderRadius={2}
        bgcolor="white"
        sx={{
          overflowX: "hidden",
          display: { xs: "flex", lg: "block" },
          justifyContent: "center",
          overflow: "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
          },
          "&:hover::-webkit-scrollbar-thumb": {
            backgroundColor: "#888888",
          },
          "&:active::-webkit-scrollbar-thumb": {
            backgroundColor: "#f0f0f0",
          },
          "&:active::-webkit-scrollbar-thumb, &:hover::-webkit-scrollbar-thumb":
            {
              transition: "background-color 0.1s",
            },
        }}
      >
        {time ? (
          <Box gap={2} display="flex" justifyContent="center">
            <Box
              display="flex"
              justifyContent="center"
              minWidth="100px"
              sx={{ border: "1px solid #ccc", padding: 1 }}
            >
              <Typography>{time && formatarHora(parseInt(time))}</Typography>
            </Box>
            <Button variant="contained" onClick={() => setTime("")}>
              Alterar
            </Button>
          </Box>
        ) : (
          <Box>
            {dayList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  transition:
                    "max-width 0.5s ease, transform 0.5s ease, width 10s ease",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 1,
                    width: "100%",

                    bgcolor:
                      showConfirmButton === moment(item.start).hour()
                        ? "#Ebebeb"
                        : "white",
                    color:
                      showConfirmButton === moment(item.start).hour()
                        ? "#Ebebeb"
                        : "#666666",

                    transition:
                      "max-width 0.5s ease, transform 0.5s ease, width 10s ease",

                    display: "flex",
                    justifyContent: "center",
                  }}
                  m={1}
                  mt={2}
                  mb={1}
                >
                  <Button
                    // disabled={step > 0 ? false : true}
                    disableRipple
                    sx={{
                      bgcolor:
                        showConfirmButton === moment(item.start).hour()
                          ? "#Ebebeb"
                          : "white",
                      "&:hover": {
                        boxShadow: "none",
                        cursor:
                          showConfirmButton === moment(item.start).hour()
                            ? "default"
                            : "auto",
                      },
                      "&:active": {
                        boxShadow: "none",
                      },
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 600,
                      color:
                        showConfirmButton === moment(item.start).hour()
                          ? "#323232"
                          : "#666666",
                    }}
                    onClick={() =>
                      setShowConfirmButton(moment(item.start).hour())
                    }
                  >
                    {formatarHora(moment(item.start).hour())}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display:
                      moment(item.start).hour() === showConfirmButton
                        ? "block"
                        : "none",
                  }}
                  id={moment(item.start).hour().toString()}
                  m={1}
                  mt={2}
                  mb={1}
                >
                  <Slide
                    direction="left"
                    in={
                      showConfirmButton === moment(item.start).hour()
                        ? true
                        : false
                    }
                    mountOnEnter
                    unmountOnExit
                  >
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "capitalize",
                        "&:hover": {
                          boxShadow: "none",
                          color: "white",
                        },
                        "&:active": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={handleSelectedTime}
                    >
                      Confirmar
                    </Button>
                  </Slide>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default memo(TimeCardList);
