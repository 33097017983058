import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { getPatientAndProBySessionDate } from "../../../api/InternalControl";
import LogRow from "../LogRow";
import { Box } from "@mui/system";
import Loading from "../../Loading";
import { ILogRow, Sessao } from "../../../api/types/logs";
import "dayjs/locale/pt-br";
import dayjs, { Dayjs } from "dayjs";

interface ClinicTableProps {
  date: Dayjs | null;
}
export default function ClinicTable({ date }: ClinicTableProps) {
  const [data, setData] = useState<ILogRow[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const mutationLogRow = useMutation(getPatientAndProBySessionDate, {
    onSuccess: (data) => {
      console.log(data);
      setData(data.data);
    },
    retry: 0,
  });

  useEffect(() => {
    const formattedDate = dayjs(date).format("YYYY-MM-DD");
    mutationLogRow.mutateAsync({
      date: formattedDate,
    });
  }, [date]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

  return (
    <Box>
      <Box>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Agendamento
              </TableCell>
              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Horário
              </TableCell>
              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Paciente
              </TableCell>
              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Psicologa
              </TableCell>
              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Tipo de Assinatura
              </TableCell>

              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Assinaturas
              </TableCell>

              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Status de Pagamento
              </TableCell>

              <TableCell
                sx={{ color: "#355425", fontWeight: 600 }}
                align="left"
              >
                Ferramenta
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {mutationLogRow.isLoading && <Loading />}
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((row, key) => (
              <React.Fragment key={key}>
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <LogRow date={date} keyDetails={key} row={row} />
                </TableRow>
              </React.Fragment>
            ))}

            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={8} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 75, 100, 125, 150, 175, 200]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
