import { IConfirmationEvent } from "../pages/appointment/type";
import http from "./http";

interface IAppointment {
  id: string;
  professionalEmail: string;
  clientEmail: string;
  date: Date;
}

//post create appointment
export async function CreateAppointment(appointment: IAppointment) {
  const response = await http.post("/api/", appointment);
  return response.data;
}

export async function confirmEvent(data: IConfirmationEvent) {
  const resp = await http.post("/api/confirmationsEvent", data);
  return resp.data;
}

export async function getStatus(marketplaceId: string): Promise<string> {
  const resp = await http.get(`/api/statusAgendamento/${marketplaceId}`);
  return resp.data.statusLog;
}
