import React, { useState, useEffect, memo } from "react";
import { Box, Button, Slide, Typography } from "@mui/material";
import { getEventsByDay } from "../../api/Agenda";
import { IAvalibleEvent } from "../../api/Agenda";
import { IAgendaConfirmation } from "./type";
import moment from "moment";
import "dayjs/locale/pt-br";
import "moment/locale/pt-br";
import { useMutation } from "@tanstack/react-query";
import { formatarHora } from "../../utils/timeFormat";
import { eventsMockup } from "../../utils/mockups/AvalibleEventMockup";
import Loading from "../Loading";

//nova logica para garantir data correr vindo do backend
function TimeCardList({
  id,
  setStep,
  setTime,
  time,
  step,
}: IAgendaConfirmation) {
  const dataConsultaString = sessionStorage.getItem("dataConsulta") || "";
  const [dataConsulta, setDataConsulta] =
    useState<string | null>(dataConsultaString);
  const [dataConsultaAnterior, setDataConsultaAnterior] =
    useState<string | null>(null);
  const [dayList, setDayList] = useState<IAvalibleEvent[]>(eventsMockup);

  const [showConfirmButton, setShowConfirmButton] =
    useState<number | null>(null);

  //Recebe os valores disponívies na agenda do profissional
  const mutationAvalibleEvents = useMutation(getEventsByDay, {
    onSuccess: (data: IAvalibleEvent[]) => {
      setDayList(data);
    },
    retry: 0,
  });
  //tem que mudar a logica para nao ficar pesquisando todos os horários

  useEffect(() => {
    const dataConsultaString = sessionStorage.getItem("dataConsulta") || "";

    if (dataConsultaString && id && step > 0) {
      setDayList([]);

      const dataConsulta = dataConsultaString.replace(/"/g, "").slice(0, 10);
      mutationAvalibleEvents.mutate({ id: id, day: dataConsulta });
    }
  }, []);

  useEffect(() => {
    if (time) {
      console.log(time);
    }
  }, [time]);

  //Atualiza selectedTime e IAvalibleEvent
  const handleSelectedTime = () => {
    if (showConfirmButton) {
      setTime(showConfirmButton.toString());
      setStep(2); // controle de amostra de painel para versão mobile
      console.log("showConfirmButton :::", showConfirmButton);
    }
  };

  // if (mutationAvalibleEvents.isLoading) {
  //   return <Loading />;
  // }

  return (
    <Box width="100%" height="100%">
      <Box
        boxShadow="rgba(0, 0, 0, 0.15) 0px 2px 8px;"
        maxHeight="100%"
        width="100%"
        p={time ? 2 : 0}
        borderRadius={2}
        bgcolor="white"
        sx={{
          overflowX: "hidden",
          display: { xs: "flex", lg: "block" },
          justifyContent: "center",
          overflow: "auto",
          scrollbarWidth: "thin",
          "&::-webkit-scrollbar-thumb": {
            backgroundColor: "transparent",
          },
          "&:hover::-webkit-scrollbar-thumb": {
            backgroundColor: "#888888",
          },
          "&:active::-webkit-scrollbar-thumb": {
            backgroundColor: "#f0f0f0",
          },
          "&:active::-webkit-scrollbar-thumb, &:hover::-webkit-scrollbar-thumb":
            {
              transition: "background-color 0.1s",
            },
        }}
      >
        {time !== "" ? (
          <Box gap={2} display="flex" justifyContent="center">
            <Box
              display="flex"
              justifyContent="center"
              sx={{ border: "1px solid #ccc", width: "50%", padding: 1 }}
            >
              <Typography>{formatarHora(parseInt(time))}</Typography>
            </Box>
            <Button variant="contained" onClick={() => setTime("")}>
              Alterar
            </Button>
          </Box>
        ) : (
          <Box>
            {mutationAvalibleEvents.isLoading && <Loading />}
            {dayList.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  width: "250px",

                  transition:
                    "max-width 0.5s ease, transform 0.5s ease, width 10s ease",
                  maxWidth:
                    showConfirmButton === moment(item.start).hour()
                      ? "400px"
                      : "250px",
                }}
              >
                <Box
                  sx={{
                    border: "1px solid #ccc",
                    borderRadius: 1,
                    width: "250px",

                    bgcolor:
                      showConfirmButton === moment(item.start).hour()
                        ? "#Ebebeb"
                        : "white",
                    color:
                      showConfirmButton === moment(item.start).hour()
                        ? "#Ebebeb"
                        : "#666666",

                    transition:
                      "max-width 0.5s ease, transform 0.5s ease, width 10s ease",
                    maxWidth:
                      showConfirmButton === moment(item.start).hour()
                        ? "400px"
                        : "250px",

                    display: "flex",
                    justifyContent: "center",
                  }}
                  m={1}
                  mt={2}
                  mb={1}
                >
                  <Button
                    disabled={step > 0 ? false : true}
                    disableRipple
                    sx={{
                      bgcolor:
                        showConfirmButton === moment(item.start).hour()
                          ? "#Ebebeb"
                          : "white",
                      "&:hover": {
                        boxShadow: "none",
                        cursor:
                          showConfirmButton === moment(item.start).hour()
                            ? "default"
                            : "auto",
                      },
                      "&:active": {
                        boxShadow: "none",
                      },
                      width: "100%",
                      textAlign: "center",
                      fontWeight: 600,
                      color:
                        showConfirmButton === moment(item.start).hour()
                          ? "#323232"
                          : "#666666",
                    }}
                    onClick={() =>
                      setShowConfirmButton(moment(item.start).hour())
                    }
                  >
                    {formatarHora(moment(item.start).hour())}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display:
                      moment(item.start).hour() === showConfirmButton
                        ? "block"
                        : "none",
                  }}
                  id={moment(item.start).hour().toString()}
                  m={1}
                  mt={2}
                  mb={1}
                >
                  <Slide
                    direction="left"
                    in={
                      showConfirmButton === moment(item.start).hour()
                        ? true
                        : false
                    }
                    mountOnEnter
                    unmountOnExit
                  >
                    <Button
                      disableRipple
                      sx={{
                        textTransform: "capitalize",
                        "&:hover": {
                          boxShadow: "none",
                          color: "white",
                        },
                        "&:active": {
                          boxShadow: "none",
                        },
                      }}
                      variant="contained"
                      onClick={handleSelectedTime}
                    >
                      Confirmar
                    </Button>
                  </Slide>
                </Box>
              </Box>
            ))}
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default memo(TimeCardList);
