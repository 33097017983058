import { styled } from "@mui/material/styles";
import { Grid, Box, Button } from "@mui/material";
import { CSSProperties } from "react";

const ClinicWrap = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",
  backgroundColor: "#efefef",
  minHeight: "100vh",
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.up("md")]: {},
}));

const CustomButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.up("md")]: {},
}));

const Border = styled(Grid)(({ theme }) => ({
  padding: 20,

  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.up("md")]: {},
}));
const Main = styled(Grid)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "start",

  [theme.breakpoints.down("md")]: {},
  [theme.breakpoints.up("md")]: {},
}));

export { ClinicWrap, CustomButton, Main, Border };
