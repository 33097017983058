import http from "./http";
import { Patient } from "./types/patient";

export async function getPatientById(id: string): Promise<Patient> {
  const response = await http.get(`/api/patient/byId/${id}`);
  return response.data;
}

export async function getPatients(): Promise<Patient[]> {
  const response = await http.get(`/api/patient`);
  console.log(response.data.data);
  return response.data.data;
}
