import React from "react";
import {
  Box,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { days, weekPortuguese } from "../../../utils/calendarConfig";
import { IProfessional, TimeRange } from "../../../api/types/professionals";

const HorarioAtendimentoTable = ({
  professional,
}: {
  professional: IProfessional;
}) => {
  //   const formatWorkSlots = (workSlots: TimeRange[]) => {
  //     let formattedSlots = "";
  //     let start = null;
  //     let end = null;

  //     for (const slot of workSlots) {
  //       if (start === null) {
  //         start = slot.inicio;
  //         end = slot.fim;
  //       } else {
  //         if (slot.inicio === end) {
  //           end = slot.fim;
  //         } else {
  //           formattedSlots += `${start}-${end} | `;
  //           start = slot.inicio;
  //           end = slot.fim;
  //         }
  //       }
  //     }

  //     formattedSlots += `${start}-${end}`;
  //     return formattedSlots;
  //   };

  const formatWorkSlots = (workSlots: TimeRange[]) => {
    let formattedSlots = "";
    for (let i = 0; i < workSlots.length; i++) {
      const slot = workSlots[i];
      formattedSlots += `${slot.inicio} - ${slot.fim}`;
      if (i < workSlots.length - 1) {
        formattedSlots += " | ";
      }
    }
    return formattedSlots;
  };
  return (
    <Box p={2}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Dia</TableCell>
              <TableCell>Atendimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {professional.workHours.map((item, key) => (
              <TableRow key={key}>
                <TableCell>{days[item.day]}</TableCell>
                <TableCell>{formatWorkSlots(item.timeRanges)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HorarioAtendimentoTable;
