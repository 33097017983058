import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { AppointmentProvider } from "./context/StepperContext";
import { ControlProvider } from "./context/ControlContext";
import AppRouter from "./routes/AppRouter";
import { AgendaProvider } from "./context/AgendaContext";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { PsiTheme } from "./desing-system/design-tokens/theme";
import { SnackbarProvider } from "notistack";
import { MaterialDesignContent } from "notistack";
import styled from "@emotion/styled";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { useAuth } from "./hooks/useAuth";
import Home from "./pages/home/Home";
import Match from "./pages/match/Match";
import Appointment from "./pages/appointment/Appointment";
import Payment from "./pages/payment/Payment";
import AlreadyPatient from "./pages/AlreadyPatient/AlreadyPatient";
import ConfirmAppointment from "./pages/appointment/ConfirmAppointment";
import SignIn from "./pages/SingIn/SingIn";
import { InfoPanel } from "./pages/internal/InfoPanel";
import InteralAppointment from "./pages/internal/InteralAppointment";
import PackageFollowUp from "./pages/private/InternalControl/PackageFollowUp";
import ClinicHome from "./pages/private/InternalControl/ClinicHome";
import NotFound from "./pages/notFound/NotFound";
import PatientProfile from "./pages/private/patient/PatientProfile";
import DashboardPatient from "./pages/private/patient/DashboardPatient";

const erroDesignContainer = styled(MaterialDesignContent)(() => ({
  "&.notistack-MuiContent-error": {
    backgroundColor: "rgb(253, 237, 237)",
    border: "1px solid red",
    color: "rgb(95, 33, 32)",
  },
}));
let theme = createTheme();
theme = responsiveFontSizes(PsiTheme);

const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <AppointmentProvider>
        <ControlProvider>
          <AgendaProvider>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              <SnackbarProvider
                maxSnack={1}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                Components={{
                  error: erroDesignContainer,
                }}
              >
                <AppRouter />
              </SnackbarProvider>
            </ThemeProvider>
          </AgendaProvider>
        </ControlProvider>
      </AppointmentProvider>
    </QueryClientProvider>
  );
}

export default App;
